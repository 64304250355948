import { Player } from "../../../Models/Entities/Player";
export const UPDATE_PLAYER = "UPDATE_PLAYER";

export const updatePlayer = (player: Player) => {
  return { type: UPDATE_PLAYER, payload: { player: player } };
};

export interface PlayerAction {
  type: typeof UPDATE_PLAYER;
  payload: { player: Player };
}
