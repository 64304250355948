import images from "../../Assets/Sprites/Sprites";

import { Loader, Spritesheet, utils } from "pixi.js";
import { Game } from "../../Core/GameEngine/Game";

export class AssetLoader {
  private static instance: AssetLoader;
  private loader: Loader;
  private _spriteSheets: Map<string, Spritesheet>;
  public get spriteSheets(): Map<string, Spritesheet> {
    return this._spriteSheets;
  }

  private constructor() {
    this.loader = Loader.shared;
    this._spriteSheets = new Map<string, Spritesheet>();
  }

  public static getInstance(): AssetLoader {
    if (!AssetLoader.instance) {
      AssetLoader.instance = new AssetLoader();
    }

    return AssetLoader.instance;
  }

  public loadAsset() {
    try {
      for (const [key, value] of images.entries()) {
        this.loader.add(key, value.img);
      }
      this.loader.load(() => {
        this.doneLoading(this);
      });
    } catch (error) {
      console.log(error);
    }
  }
  private doneLoading(self: AssetLoader) {
    for (const [key, value] of images.entries()) {
      const x = new Spritesheet(self.loader.resources[key].texture, value.json);
      x.parse(() => {
        console.log("Done processing " + key + "...");
      });

      self._spriteSheets.set(key, x);
      utils.clearTextureCache();
    }
    Game.instance.loadPlayer();
  }
}
