import { playerUpdater } from "./Reducers/PlayerReducer";
import { navigation } from "./Reducers/NavigationReducer";
import { createStore, combineReducers } from "redux";

// TODO: Import reducers

export const rootReducer = combineReducers({
  navigation,
  playerUpdater,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer);
