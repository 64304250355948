import { STAT } from "../../Components/PlayerStats/SubComponents/MainStats";
import { Trainer } from "../../Components/Training/Models/Trainer";

export class TrainerManager {
  private static instance: TrainerManager;

  private _map = new Map<STAT, Trainer>();

  private constructor() {}

  public static getInstance() {
    if (!TrainerManager.instance) {
      TrainerManager.instance = new TrainerManager();

      TrainerManager.instance.map.set(
        STAT.STRENGTH,
        new Trainer(STAT.STRENGTH)
      );
      TrainerManager.instance.map.set(
        STAT.DEXTERITY,
        new Trainer(STAT.DEXTERITY)
      );
      TrainerManager.instance.map.set(
        STAT.INTELLIGENCE,
        new Trainer(STAT.INTELLIGENCE)
      );
    }
    return TrainerManager.instance;
  }

  public get map() {
    return this._map;
  }
}
