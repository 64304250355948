import { Dexterity } from "./Attributes/BaseAttributes/Dexterity";
import { Intelligence } from "./Attributes/BaseAttributes/Intelligence";
import { Strength } from "./Attributes/BaseAttributes/Strength";
import { Vitality } from "./Attributes/BaseAttributes/Vitality";
import { Attack } from "./Attributes/SubAttributes/Attack";
import { AttackSpeed } from "./Attributes/SubAttributes/AttackSpeed";
import { CritChance } from "./Attributes/SubAttributes/CritChance";
import { CritMultiplier } from "./Attributes/SubAttributes/CritMultiplier";
import { Health } from "./Attributes/SubAttributes/Health";
import { HealthRegen } from "./Attributes/SubAttributes/HealthRegen";
import { Mana } from "./Attributes/SubAttributes/Mana";
import { ManaRegen } from "./Attributes/SubAttributes/ManaRegen";
import { MovementSpeed } from "./Attributes/SubAttributes/MovementSpeed";

export class Stats {
  //! Base attributes:
  private _strength: Strength = new Strength(1);
  private _dexterity: Dexterity = new Dexterity(1);
  private _intelligence: Intelligence = new Intelligence(1);
  private _vitality: Vitality = new Vitality(1);

  //! Sub atttributes:
  private _health: Health = new Health(10);
  private _healthRegen: HealthRegen = new HealthRegen(1);
  private _mana: Mana = new Mana(10);
  private _manaRegen: ManaRegen = new ManaRegen(1);

  //! Combat attributes
  private _attack: Attack = new Attack(1);
  private _attackSpeed: AttackSpeed = new AttackSpeed(0.25);
  private _critChance: CritChance = new CritChance(0.0);
  private _critMultiplier: CritMultiplier = new CritMultiplier(2.0);

  //! Special attributes
  private _movementSpeed: MovementSpeed = new MovementSpeed(1.0);

  public constructor(
    strength: number = 1,
    dexterity: number = 1,
    intelligence: number = 1,
    vitality: number = 1
  ) {
    this._strength = new Strength(strength);
    this._dexterity = new Dexterity(dexterity);
    this._intelligence = new Intelligence(intelligence);
    this._vitality = new Vitality(vitality);

    //! Adding attributes
    // Sub atttributes:
    this._health.addAttribute(this._vitality);
    this._healthRegen.addAttribute(this._vitality);
    this._mana.addAttribute(this._intelligence);
    this._manaRegen.addAttribute(this._intelligence);

    // Combat attributes
    this._attack.addAttribute(this._strength);
    this._attackSpeed.addAttribute(this._dexterity);

    // Special attributes
    this._movementSpeed.addAttribute(this._dexterity);

    //? ----------------------------------------------

    //! Recalculating stats
    // Sub atttributes:
    this._health.calculateValue();
    this._healthRegen.calculateValue();
    this._mana.calculateValue();
    this._manaRegen.calculateValue();

    // Combat attributes
    this._attack.calculateValue();
    this._attackSpeed.calculateValue();

    // Special attributes
    this._movementSpeed.calculateValue();
  }

  public get strength() {
    return this._strength;
  }
  public get dexterity() {
    return this._dexterity;
  }
  public get intelligence() {
    return this._intelligence;
  }
  public get vitality() {
    return this._vitality;
  }

  public get health() {
    return this._health;
  }
  public get healthRegen() {
    return this._healthRegen;
  }

  public get mana() {
    return this._mana;
  }
  public get manaRegen() {
    return this._manaRegen;
  }

  public get attack() {
    return this._attack;
  }
  public get attackSpeed() {
    return this._attackSpeed;
  }
  public get critChance() {
    return this._critChance;
  }
  public get critMultiplier() {
    return this._critMultiplier;
  }

  public get movementSpeed() {
    return this._movementSpeed;
  }
}
