import { Page } from "../../Managers/PageManager";

export const CHANGE_PAGE = "CHANGE_PAGE";

export const changePage = (page: Page) => {
  return { type: CHANGE_PAGE, page: page };
};

export interface NavigationAction {
  type: typeof CHANGE_PAGE;
  page: Page;
}
