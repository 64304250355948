import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CooldownManager } from "../Utils/Managers/CooldownManager";
import { TrainerManager } from "../Utils/Managers/TrainerManager";
import { Page, PageManager } from "../Utils/Managers/PageManager";

import { PlayerPage } from "./PlayerPage";
import { TrainPage } from "./TrainPage";
import { DungeonPage } from "./DungeonPage";

import "./Styles/Main.css";
import { Header } from "../Components/Common/Header";
import { Game } from "../Core/GameEngine/Game";

export const Main = (props) => {
  const navigation = useSelector((state) => state.navigation);
  let pm = useRef(PageManager.getInstance());
  let game = Game.instance;

  useEffect(() => {
    Game.instance.start();
  }, [game]);

  // INIT MANAGERS
  Game.dispatch = useDispatch();
  PageManager.getInstance();
  CooldownManager.getInstance();
  TrainerManager.getInstance();

  const changePage = (page: Page) => {
    switch (page) {
      case Page.Player:
        pm.current.changePage(page);
        return <PlayerPage />;
      case Page.Train:
        pm.current.changePage(page);
        return <TrainPage />;
      case Page.Dungeon:
        pm.current.changePage(page);
        return <DungeonPage />;
      case Page.Shop:
        pm.current.changePage(page);
        return <div />;
      case Page.Inventory:
        pm.current.changePage(page);
        return <div />;
      default:
        return <div />;
    }
  };

  return (
    <div style={props.style}>
      <Header
        style={{
          display: "flex",
          minHeight: "10%",
          borderBottom: "2px solid #dddddd",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
      <div style={{ marginTop: "2vh", flex: 1 }}>
        {changePage(navigation.page)}
      </div>
    </div>
  );
};
