import { Entity } from "../Entities/Entity";
import { ActionResult } from "./ActionResult";

export abstract class Action {
  private e: Entity;

  constructor(e: Entity) {
    this.e = e;
  }

  public perform(): ActionResult {
    return this.sucess();
  }

  protected sucess(): ActionResult {
    return new ActionResult(undefined, "Sucess");
  }
  protected fail(): ActionResult {
    return new ActionResult(undefined, "Fail");
  }
  protected alternate(action: Action) {
    return new ActionResult(action, "Next action");
  }
}
