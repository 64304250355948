import React, { useState } from "react";
import Dungeon from "../Components/Dungeon";
import { DUNGEON_TYPE } from "../Core/GameEngine/Game";
import images from "../Assets/Dungeons/index";

export const DungeonPage = () => {
  const [dungeon, setDungeon] = useState(DUNGEON_TYPE.NONE);

  const selectDungeon = (d: DUNGEON_TYPE) => {
    switch (d) {
      case DUNGEON_TYPE.FOREST:
        return <Dungeon type={d} id={"Forest"} back={backButton} />;
      case DUNGEON_TYPE.CAVE:
        return <Dungeon type={d} id={"Cave"} back={backButton} />;
      default:
        return (
          <div>
            <h2 style={{ marginTop: 0 }}>Forest</h2>
            <img
              src={images.get("Forest")?.img}
              style={{
                width: "50%",
                border: "1px solid #dddddd",
                borderRadius: "10px",
              }}
              alt="Test"
              onClick={() => {
                setDungeon(DUNGEON_TYPE.FOREST);
              }}
            />
            <h2>Cave</h2>
            <img
              src={images.get("Cave")?.img}
              style={{
                width: "50%",
                border: "1px solid #dddddd",
                borderRadius: "10px",
              }}
              alt="Test"
              onClick={() => {
                setDungeon(DUNGEON_TYPE.CAVE);
              }}
            />
          </div>
        );
    }
  };

  const backButton = () => {
    setDungeon(DUNGEON_TYPE.NONE);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {selectDungeon(dungeon)}
    </div>
  );
};
