import {
  AnimatedSprite,
  Container,
  Graphics,
  Rectangle,
  Spritesheet,
} from "pixi.js";
import { Entity } from "../../../Models/Entities/Entity";
import { ENTITY_STATE } from "../../../Models/Entities/State Machine/EntityStateMachine";
import { Monster } from "../../../Models/Entities/Monsters/Monster";
import { Minion } from "../../../Models/Entities/Summons/Minion";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";
import { BattleMessageType } from "../../BattleEngine/BattleMessage";
import { Game } from "../../GameEngine/Game";
import HealthBarCointainer from "../Common/HealthBar";

export class EntitySprite extends Container {
  protected e: Entity;

  protected _spriteSheet: Spritesheet;
  protected attackKeys: string[] = [];
  protected _sprite: AnimatedSprite;
  protected _spriteThumbnail: AnimatedSprite;
  protected _healthBar: HealthBarCointainer;
  protected _hitBox: Graphics;

  protected _xAnchor: number;
  protected _yAnchor: number;

  protected _waitingForAnimation: boolean = false;
  public get waitingForAnimation(): boolean {
    return this._waitingForAnimation;
  }

  public constructor(
    e: Entity,
    spriteSheet: Spritesheet,
    max: number,
    value: number
  ) {
    super();
    this.sortableChildren = true;

    this.e = e;
    this._spriteSheet = spriteSheet;

    this.zIndex = 1;

    this._sprite = new AnimatedSprite(spriteSheet.animations["Idle"]);
    this._sprite.animationSpeed = 0.25;
    this._sprite.loop = true;
    this._sprite.updateAnchor = true;
    this._sprite.play();

    this._spriteThumbnail = new AnimatedSprite(spriteSheet.animations["Idle"]);
    this._spriteThumbnail.animationSpeed = 0.25;
    this._spriteThumbnail.loop = true;
    this._spriteThumbnail.updateAnchor = true;
    this._spriteThumbnail.play();

    for (const key in this._spriteSheet.animations) {
      if (key.includes("Attack")) this.attackKeys.push(key);
    }

    // Uncomment if I want the hitboxes to show
    this._hitBox = new Graphics();
    //this._hitBox.beginFill(0x00000aa);
    this._hitBox.drawRect(0, 0, this._sprite.width, this._sprite.height);
    //this._hitBox.endFill();
    this._hitBox.hitArea = new Rectangle(
      0,
      0,
      this._sprite.width,
      this._sprite.height
    );

    this._healthBar = new HealthBarCointainer(max, value);
    this._healthBar.y = this._sprite.height + 2;
    this._healthBar.x = this._hitBox.width / 2 - this._healthBar.barWidth / 2;

    this._healthBar.zIndex = 0;
    this._hitBox.zIndex = 1;
    this._sprite.zIndex = 2;

    this._hitBox.interactive = true;

    this._xAnchor = this.sprite.anchor.x;
    this._yAnchor = this.sprite.anchor.y;

    this.addChild(this._hitBox);
    this.addChild(this._healthBar);
    this.addChild(this._sprite);
  }

  public updateHealthBar() {
    this.healthBar.updateHealth(
      this.e.stats.health.current,
      this.e.stats.health.max
    );
  }

  public changeAnimation(state: ENTITY_STATE) {
    this._sprite.animationSpeed = 0.25;

    switch (state) {
      case ENTITY_STATE.ATTACK:
        this.zIndex = 2;

        //* Getting random attack animation based on how many different animations there are
        this._sprite.textures = this._spriteSheet.animations[
          this.attackKeys[Math.floor(Math.random() * this.attackKeys.length)]
        ];

        this._sprite.loop = false;
        this._waitingForAnimation = true;

        //TODO: REGULATE REDUCTION IN ANIMATIONS SPEED HERE
        this._sprite.animationSpeed =
          0.25 + (this.e.stats.attackSpeed.finalValue - 0.25) / 10;

        this._sprite.onComplete = () => {
          this._waitingForAnimation = false;
          const target = this.e.target;
          if (target) {
            const damage = this.e.basicAttack();
            target.stats.health.current -= damage;
            //TODO: Need more work here
            if (target.stats.health.current <= 0) {
              this.e.stateMachine.overrideNext(ENTITY_STATE.SEARCH);
              if (
                target.stateMachine.nextState === ENTITY_STATE.DEAD ||
                target.stateMachine.currentState === ENTITY_STATE.DEAD
              )
                return;
              target.stateMachine.overrideNext(ENTITY_STATE.DEAD);
              if (target.className === "Monster") {
                this.e.createBattleMessage(BattleMessageType.KILLED);
                const xp = (target as Monster).xp;
                const gold = (target as Monster).gold;
                const minions = this.e.assignedBattle?.battleObjects.filter(
                  (x) => x.className === "Minion"
                );

                if (minions && minions.length > 0) {
                  (minions as Minion[]).forEach((m) => {
                    m.addXp(xp / (2 * minions.length));
                  });
                  Game.Player.addXp(xp / 2);
                } else {
                  Game.Player.addXp(xp);
                }
                Game.Player.addGold(gold);
                Game.dispatch(updatePlayer(Game.Player));
              } else {
                this.e.createBattleMessage(BattleMessageType.DIED);
              }
              target.animation?.sprite.updateHealthBar();
              return;
            }
            target.animation?.sprite.updateHealthBar();

            //! IF WE NEED TO UPDATE THE PLAYER INFORMATION
            if (target.className === "Player") {
              Game.dispatch(updatePlayer(Game.Player));
            }

            this.e.createBattleMessage(BattleMessageType.ATTACK, damage);

            //! WAIT ANIMATION UNTIL ATTACK ANIMATION CAN BE DONE AGAIN
            this.e.animation?.stateQueue.push(ENTITY_STATE.WAIT);
          }
        };
        break;
      case ENTITY_STATE.MOVE:
        this.zIndex = 1;
        this._sprite.textures = this._spriteSheet.animations["Run"];
        this.sprite.loop = true;
        this._sprite.onComplete = () => {};
        break;
      case ENTITY_STATE.DEAD:
        this.zIndex = 0;
        this._sprite.textures = this._spriteSheet.animations["Death"];
        this._waitingForAnimation = true;
        this._sprite.loop = false;
        this._sprite.onComplete = () => {
          this._waitingForAnimation = false;
          this._hitBox.interactive = false;
        };
        break;
      default:
        this.zIndex = 1;
        this._sprite.textures = this._spriteSheet.animations["Idle"];
        this._sprite.loop = true;
        this._sprite.onComplete = () => {};
        break;
    }
    this.updateThumbnail();
    this._sprite.play();
  }

  public get xAnchor(): number {
    return this._xAnchor;
  }
  public get yAnchor(): number {
    return this._yAnchor;
  }

  public get healthBar(): HealthBarCointainer {
    return this._healthBar;
  }
  public get sprite(): PIXI.AnimatedSprite {
    return this._sprite;
  }
  public get hitBox(): PIXI.Graphics {
    return this._hitBox;
  }
  public get spriteSheet(): PIXI.Spritesheet {
    return this._spriteSheet;
  }

  public setPosition(x, y) {
    this.position.set(x, y);
  }

  protected updateThumbnail() {
    this._spriteThumbnail.textures = this._sprite.textures;
    this._spriteThumbnail.animationSpeed = this._sprite.animationSpeed;
    this._spriteThumbnail.loop = this._sprite.loop;
    this._spriteThumbnail.play();
  }

  public get spriteThumbnail(): AnimatedSprite {
    return this._spriteThumbnail;
  }
}
