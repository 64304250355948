import { Viewport } from "pixi-viewport";
import * as PIXI from "pixi.js";
import { AssetLoader } from "../../Utils/Loaders/AssetLoader";

export enum RENDER_STATE {
  PLAY,
  STOP,
  DESTROY,
}

export abstract class GameController {
  //public init: Promise<void>;

  protected static _WIDTH = 1800;

  public static get WIDTH() {
    return GameController._WIDTH;
  }

  protected static _HEIGHT = 1200;

  public static get HEIGHT() {
    return GameController._HEIGHT;
  }

  protected _viewport: Viewport;

  public get viewport() {
    return this._viewport;
  }

  protected _ref;

  private destroyed: boolean = false;
  protected state = this.play;

  protected _renderer: PIXI.Renderer;
  protected stage: PIXI.Container;

  public get renderer(): PIXI.Renderer {
    return this._renderer;
  }
  public set ref(value) {
    this._ref = value;
  }

  protected loader: AssetLoader = AssetLoader.getInstance();

  protected play() {}
  protected stop() {}
  protected destroy() {
    this._renderer.destroy();
    PIXI.utils.clearTextureCache();
  }

  public changeState(state: RENDER_STATE) {
    switch (state) {
      case RENDER_STATE.PLAY:
        this.state = this.play;
        break;
      case RENDER_STATE.STOP:
        this.state = this.stop;
        break;
      default:
        this.state = this.destroy;
        break;
    }
  }

  constructor() {
    this._renderer = PIXI.autoDetectRenderer({
      backgroundColor: 0x5bba6f,
      transparent: false,
      antialias: true,
      resolution: window.devicePixelRatio,
      width: 100,
      height: 100,
      autoDensity: true,
    });
    this.stage = new PIXI.Container();

    this._viewport = new Viewport({
      screenWidth: this._renderer.width,
      screenHeight: this._renderer.height,
      worldWidth: GameController._WIDTH,
      worldHeight: GameController._HEIGHT,

      interaction: this._renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    this.stage.addChild(this._viewport);
    this._viewport
      .drag()
      .bounce()
      .pinch()
      .wheel({ smooth: 1, percent: 0.01 })
      .decelerate()
      .clampZoom({ minScale: 0.35, maxScale: 5 });
  }
}
