type Owner = {
  mounted: boolean;
};
export class Cooldown {
  private tID;
  private _counter: number;
  private tickRate: number = 1000;

  private owner: Owner;

  private stateMap = new Map<
    String,
    React.Dispatch<React.SetStateAction<number>>
  >();

  public constructor(owner: Owner) {
    this.tID = null;
    this._counter = 0;
    this.owner = owner;
  }

  public start(x: number) {
    if (this.tID == null) {
      this._counter = x;
      if (this.owner.mounted)
        this.stateMap.forEach((s) => {
          s(this._counter);
        });
      this.tID = setInterval(() => {
        this._counter -= this.tickRate / 1000;
        if (this.owner.mounted)
          this.stateMap.forEach((s) => {
            s(this._counter);
          });
        if (this._counter <= 0) {
          clearInterval(this.tID);
          this.tID = null;
        }
      }, this.tickRate);
    }
  }

  public addState(
    key: String,
    state: React.Dispatch<React.SetStateAction<number>>
  ) {
    this.stateMap.set(key, state);
  }
  public get counter(): number {
    return this._counter;
  }
}
