import React from "react";
import { Progress } from "./progress";
import { TrainerManager } from "../../Utils/Managers/TrainerManager";
import { STAT } from "../PlayerStats/SubComponents/MainStats";

TrainerManager.getInstance();

export const Train = () => {
  return (
    <div>
      <Progress text="PUSH UPS" stat={STAT.STRENGTH} />
      <Progress text="RUNNING" stat={STAT.DEXTERITY} />
      <Progress text="MEDITATION" stat={STAT.INTELLIGENCE} />
    </div>
  );
};
