import { Entity } from "../Entity";
import { Minion } from "../Summons/Minion";
import { Item, Gold } from "../../Items/Item";

import { MonsterSprite } from "../../../Core/PixiJS/Entities/MonsterSprite";
import { EntitySprite } from "../../../Core/PixiJS/Entities/EntitySprite";
import { Game } from "../../../Core/GameEngine/Game";
import { SummonSprite } from "../../../Core/PixiJS/Entities/SummonSprite";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Stats } from "../../Stats/Stats";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";

export class Monster extends Entity {
  private _xp: number;
  private _gold: number;
  private drops: Item[];
  private _chance: number = 0;
  private _left: number = 0;

  constructor(
    name: string,
    level: number,
    stats: Stats,
    xp: number = 200,
    chance: number = 0,
    gold: number = 10,
    drops: Item[] = [new Gold(10)],
    sprite: string
  ) {
    super("Monster", name, level, sprite);
    this._stats = stats;
    this._xp = xp;
    this._chance = chance;
    this._gold = gold;
    this.drops = drops;
    this._left = 3;
  }

  public get left(): number {
    return this._left;
  }
  public get chance(): number {
    return this._chance;
  }
  public get xp(): number {
    return this._xp;
  }
  public get gold(): number {
    return this._gold;
  }

  public reanimate() {
    if (Game.Player.stats.mana.current >= this._stats.health.max) {
      if (Math.random() <= this._chance) {
        Game.Player.stats.mana.current -= this._stats.health.max;
        this.assignedBattle?.removeObject(this);
        const minion = new Minion(
          this._name,
          this._level,
          this._stats,
          this._stats.health.max,
          "Rookie",
          this.spriteName
        );
        minion.stats.health.current = minion.stats.health.max;
        if (minion.animation) {
          //! UPDATE HEALTH BAR
          (minion.animation.sprite as SummonSprite).updateHealthBar();

          //! CHANGE NEXT STATE TO REANIMATE, AND ANIMATION TO DEAD
          minion.stateMachine.nextState = ENTITY_STATE.REANIMATE;
          minion.animation.sprite.changeAnimation(ENTITY_STATE.DEAD);

          //! UPDATE TO CORRECT START FRAME
          minion.animation.sprite.sprite.gotoAndPlay(
            minion.animation.sprite.sprite.totalFrames - 1
          );
          minion.animation.sprite.spriteThumbnail.gotoAndPlay(
            minion.animation.sprite.spriteThumbnail.totalFrames - 1
          );

          //! CORRECT DIRECTION TO BE THE SAME AS MONSTER
          minion.physics.direction = this._physics.direction;
          minion.animation.updateDirection(this._physics.direction);

          //! TINTING SPRITE AND THUMBNAIL
          (minion.animation.sprite as SummonSprite).sprite.tint = 0x111111;
          (minion.animation
            .sprite as SummonSprite).spriteThumbnail.tint = 0x111111;

          //! ASSIGN BATTLE TO MINION AND MINION TO THE CORRECT POSITION
          minion.assignedBattle = this.assignedBattle;
          minion.position = this._position;
        }

        //! ADDING MINION TO PLAYER ARRAY, ADD MINION TO BATTLE AND UPDATE SELECTED -> THEN DISPATCH AN UPDATE TO PLAYER
        Game.Player.addMinion(minion);
        this.assignedBattle?.addObject(minion);
        this.assignedBattle?.selectSummonable(minion);
        Game.dispatch(updatePlayer(Game.Player));
        return;
      }
      this._left -= 1;
      if (this._left === 0 && this._animation) {
        this._left = 3;

        (this._animation
          .sprite as MonsterSprite).summonAbleEffect.visible = false;
        (this._animation.sprite as EntitySprite).hitBox.interactive = false;
        this.assignedBattle?.selectSummonable(undefined);
        return;
      }
      this.assignedBattle?.updateSelected(this);
    }
  }
}
