import { Item } from "../../Models/Items/Item";

export enum BattleMessageType {
  ATTACK,
  KILLED,
  REWARD,
  EVENT,
  DIED,
}

export class BattleMessage {
  private _type: BattleMessageType;

  private _attacker: string = "";
  private _target: string = "";
  private _damage: number = 0;

  private _rewards: Item[] = [];
  private _gold: number = 0;
  private _xp: number = 0;

  private _message: string = "";

  public constructor(
    type: BattleMessageType,
    attacker?: string,
    target?: string,
    damage?: number,
    gold?: number,
    xp?: number,
    rewards?: Item[]
  ) {
    this._type = type;

    try {
      switch (type) {
        case BattleMessageType.ATTACK:
          this._attacker = attacker!;
          this._target = target!;
          this._damage = damage!;
          break;

        case BattleMessageType.KILLED:
          this._attacker = attacker!;
          this._target = target!;
          this._gold = gold!;
          this._xp = xp!;
          break;
        case BattleMessageType.DIED:
          this._attacker = attacker!;
          this._target = target!;
          break;

        default:
          this._message = "WIP, rewards or something.";
          break;
      }
    } catch (error) {
      console.log("Could not create log event: " + error);
    }
  }

  public get type(): BattleMessageType {
    return this._type;
  }
  public get attacker(): string {
    return this._attacker;
  }
  public get target(): string {
    return this._target;
  }
  public get damage(): number {
    return this._damage;
  }
  public get message(): string {
    return this._message;
  }
  public get rewards(): Item[] {
    return this._rewards;
  }
  public get gold(): number {
    return this._gold;
  }
  public get xp(): number {
    return this._xp;
  }
}
