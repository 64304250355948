import React, { useState } from "react";
import { Battle, StateTypes } from "../../../Core/BattleEngine/Battle";
import { Entity } from "../../../Models/Entities/Entity";
import { Monster } from "../../../Models/Entities/Monsters/Monster";
import { ENTITY_STATE } from "../../../Models/Entities/State Machine/EntityStateMachine";

type Props = {
  battle: Battle;
};

export const EntityBox = (props: Props) => {
  const [state, setState] = useState({
    focus: undefined,
  });
  const battle = props.battle;
  battle.stateManager.get(StateTypes.ENTITY)?.set("Entity", setState);

  if (state.focus === undefined) {
    return (
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 3,

          display: "flex",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "300px",
          height: "150px",

          border: "2px solid #dddddd",
          borderRadius: "20px",

          opacity: 0.8,

          alignItems: "center",

          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            boxShadow: "0 0 0 99999px #000000",
          }}
        ></div>
        <div>
          <p>Name</p>
          <p>Health: 1/1</p>
          <progress max={1} value={1} style={{ width: "100px" }}></progress>
        </div>
      </div>
    );
  } else if (state.focus !== undefined) {
    const t: Entity = state.focus!;

    if (
      t.stateMachine.currentState === ENTITY_STATE.DEAD &&
      (t as Monster).left > 0
    ) {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 3,

            display: "flex",
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "350px",
            height: "150px",

            border: "2px solid #dddddd",
            borderRadius: "20px",

            opacity: 0.8,

            alignItems: "center",

            overflow: "hidden",
          }}
        >
          <div
            style={{
              minWidth: "80px",
              minHeight: "80px",
              boxShadow: "0 0 0 9999px #000000",
              marginRight: "10px",
            }}
          ></div>
          <div style={{ borderLeft: "1px solid #dddddd", paddingLeft: "20px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <p>{t.name}</p>
                <p>
                  Health: {t.stats.health.current.toFixed(2)}/
                  {t.stats.health.max.toFixed(2)}
                </p>
                <progress
                  max={t.stats.health.max}
                  value={t.stats.health.current}
                  style={{ width: "100px" }}
                ></progress>
              </div>
              {t.stateMachine.currentState === ENTITY_STATE.DEAD &&
                (t as Monster).left > 0 && (
                  <div>
                    <button
                      onClick={() => {
                        (t as Monster).reanimate();
                      }}
                    >
                      Summon
                    </button>
                    <p style={{ color: "gold" }}>
                      Chances: {(t as Monster).left}
                    </p>
                    <p>
                      <span style={{ color: "dodgerblue" }}>
                        Mana cost: {t.stats.health.max}
                      </span>
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 3,

            display: "flex",
            paddingLeft: "20px",
            paddingRight: "20px",
            width: "300px",
            height: "150px",

            border: "2px solid #dddddd",
            borderRadius: "20px",

            opacity: 0.8,

            alignItems: "center",

            overflow: "hidden",
          }}
        >
          <div
            style={{
              minWidth: "80px",
              minHeight: "80px",
              boxShadow: "0 0 0 9999px #000000",
              marginRight: "10px",
            }}
          ></div>
          <div style={{ borderLeft: "1px solid #dddddd", paddingLeft: "20px" }}>
            <div>
              <p>{t.name}</p>
              <p>
                Health: {t.stats.health.current.toFixed(2)}/
                {t.stats.health.max.toFixed(2)}
              </p>
              <progress
                max={t.stats.health.max}
                value={t.stats.health.current}
                style={{ width: "100px" }}
              ></progress>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <div />;
  }
};
