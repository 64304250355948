import { DependantAttribute } from "../DependantAttribute";

export class MovementSpeed extends DependantAttribute {
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every 10 dexterity, increases movementspeed speed with 0.1
    var dexterity: number = this._otherAttributes[0].calculateValue();
    this._finalValue += Math.floor(dexterity / 10) / 10;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    return this._finalValue;
  }
}
