import { randomInt } from "../../Utils/Calculation";

export class Attack {
  private minDamage: number = 0;
  private maxDamage: number = 0;

  public damage() {
    return randomInt(this.minDamage, this.maxDamage);
  }

  constructor(min: number = 0, max: number = 0) {
    this.minDamage = min;
    this.maxDamage = max;
  }
}
