import { Entity } from "../Models/Entities/Entity";

export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const distance = (from: Entity, to: Entity) => {
  const a = from.centerPosition.x - to.centerPosition.x;
  const b = from.centerPosition.y - to.centerPosition.y;

  const dist = Math.sqrt(a * a + b * b);

  return Math.floor(dist);
};

export const closeToAttack = (from: Entity, to: Entity) => {
  if (
    distance(from, to) <=
    from.calcRange() + from.stats.movementSpeed.finalValue * 2
  ) {
    return true;
  }
  return false;
};
