import React from "react";
import "./styles/statusbar.css";
import { useSelector } from "react-redux";
import { Player } from "../../Models/Entities/Player";

export const StatusBar = () => {
  const playerUpdater = useSelector((state) => state.playerUpdater);
  let player: Player = playerUpdater.payload.player;
  return (
    <div className="topbar">
      <p style={{ color: "crimson" }}>
        HP: {player.stats.health.current.toFixed(0)}/
        {player.stats.health.max.toFixed(0)}
      </p>
      <p style={{ color: "dodgerblue" }}>
        MP: {player.stats.mana.current.toFixed(0)}/
        {player.stats.mana.max.toFixed(0)}
      </p>
      <p style={{ color: "gold" }}>GOLD: {player.gold}</p>
    </div>
  );
};
