import { Spritesheet } from "pixi.js";
import { EntitySprite } from "../../../Core/PixiJS/Entities/EntitySprite";
import { MonsterSprite } from "../../../Core/PixiJS/Entities/MonsterSprite";
import { PlayerSprite } from "../../../Core/PixiJS/Entities/PlayerSprite";
import { SummonSprite } from "../../../Core/PixiJS/Entities/SummonSprite";
import { Entity } from "../Entity";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Monster } from "../Monsters/Monster";
import { Summon } from "../Summons/Summon";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";
import { DIRECTION } from "./PhysicsComponent";

export class AnimationComponent
  extends Component
  implements ComponentInterface {
  public sprite: EntitySprite;
  public stateQueue: Array<ENTITY_STATE> = [];
  public update(delta: number) {
    //! IF NO NEED TO WAIT FOR ANIMATION
    if (
      this.stateQueue.length > 0 &&
      (!this.sprite.waitingForAnimation ||
        this.stateQueue[this.stateQueue.length - 1] === ENTITY_STATE.DEAD)
    ) {
      const state = this.stateQueue.pop();
      this.stateQueue = [];
      this.sprite.changeAnimation(state!);
    }
  }

  public updateDirection(direction: DIRECTION) {
    if (direction === DIRECTION.LEFT) {
      this.sprite.sprite.scale.x = -1;
      this.sprite.sprite.x =
        this.sprite.hitBox.width * (1 - this.sprite.xAnchor);
    } else {
      this.sprite.sprite.scale.x = 1;
      this.sprite.sprite.x = 0;
    }
  }

  constructor(e: Entity, ss: Spritesheet) {
    super(e);

    switch (e.className) {
      case "Player":
        this.sprite = new PlayerSprite(
          ss,
          this.e.stats.health.current,
          this.e.stats.health.max
        );
        break;
      case "Monster":
        this.sprite = new MonsterSprite(
          this.e as Monster,
          ss,
          this.e.stats.health.current,
          this.e.stats.health.max
        );
        break;
      case "Minion":
        this.sprite = new SummonSprite(
          this.e as Summon,
          ss,
          this.e.stats.health.current,
          this.e.stats.health.max
        );
        break;
      default:
        this.sprite = new EntitySprite(
          this.e,
          ss,
          this.e.stats.health.current,
          this.e.stats.health.max
        );
        break;
    }
  }
}
