import { Game } from "../../../Core/GameEngine/Game";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";
import { Entity } from "../Entity";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Health } from "../../Stats/Attributes/SubAttributes/Health";
import { HealthRegen } from "../../Stats/Attributes/SubAttributes/HealthRegen";
import { RegenerationComponent } from "./RegenerationComponent";

export class SummonRegenerationComponent extends RegenerationComponent {
  public constructor(e: Entity, stat: Health, regen: HealthRegen) {
    super(e, stat, regen);
  }
  public update(delta: number) {
    if (this.e.stateMachine.currentState !== ENTITY_STATE.DEAD) {
      this.regenTimer -= delta;
      if (this.regenTimer <= 0) {
        if (Game.Player.stats.mana.current <= this.regen.finalValue) {
          return;
        }
        if (this.stat.max > this.stat.current) {
          this.stat.current += this.regen.finalValue;
          Game.Player.stats.mana.current -= this.regen.finalValue;
          this.e.animation?.sprite.updateHealthBar();
          this.regenTimer = this.regenCooldown;
          Game.dispatch(updatePlayer(Game.Player));
        }
      }
    }
  }
}
