import { DependantAttribute } from "./DependantAttribute";
export class BaseAttribute {
  protected _baseValue: number;
  protected _baseMultiplier: number;
  protected _dependentAttributes: DependantAttribute[] = [];

  public constructor(value: number, multiplier: number = 0) {
    this._baseValue = value;
    this._baseMultiplier = multiplier;
  }

  public get baseValue(): number {
    return this._baseValue;
  }
  public set baseValue(value: number) {
    this._baseValue = value;
    this._dependentAttributes.forEach((a) => {
      a.calculateValue();
    });
  }
  public get baseMultiplier(): number {
    return this._baseMultiplier;
  }
  public addDependent(attr: DependantAttribute) {
    this._dependentAttributes.push(attr);
  }
  public get dependentAttributes(): DependantAttribute[] {
    return this._dependentAttributes;
  }
}
