import { closeToAttack } from "../../../Utils/Calculation";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";

export enum DIRECTION {
  LEFT,
  RIGHT,
}

export default class PhysicsComponent
  extends Component
  implements ComponentInterface {
  public direction: DIRECTION = DIRECTION.RIGHT;
  public update(delta: number) {
    if (this.e.stateMachine.currentState === ENTITY_STATE.MOVE) {
      if (this.e.target && closeToAttack(this.e, this.e.target)) {
        this.e.stateMachine.overrideNext(ENTITY_STATE.COMBAT);
        this.e.animation?.stateQueue.push(ENTITY_STATE.WAIT);
        return;
      } else if (this.e.target) {
        this.e.stateMachine.nextState = ENTITY_STATE.MOVE;
      }

      this.e.position = {
        x:
          this.e.position.x +
          this.e.velocity.dx * (this.e.stats.movementSpeed.finalValue * 2),
        y:
          this.e.position.y +
          this.e.velocity.dy * (this.e.stats.movementSpeed.finalValue * 2),
      };
    }
  }

  private directionToTarget() {
    if (this.e.target) {
      const dx = this.e.centerPosition.x - this.e.target.centerPosition.x;
      if (dx < 0) this.direction = DIRECTION.RIGHT;
      else this.direction = DIRECTION.LEFT;
    }
  }

  private distanceToTarget() {
    if (this.e.target) {
      const a = this.e.centerPosition.x - this.e.target.centerPosition.x;
      const b = this.e.centerPosition.y - this.e.target.centerPosition.y;

      const dist = Math.sqrt(a * a + b * b);

      return Math.floor(dist);
    }
    console.log("THIS GUY HAVE NO TARGET:");
    console.log(this.e);
    this.e.stateMachine.nextState = ENTITY_STATE.WAIT;
    return Infinity;
  }
}
