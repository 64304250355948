import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TrainerManager } from "../../Utils/Managers/TrainerManager";

export const Progress = (props) => {
  const [value, setValue] = useState(0);
  const [max, setMax] = useState(100);
  let dispatch = useDispatch();
  let trainer = TrainerManager.getInstance().map.get(props.stat);

  useEffect(() => {
    if (trainer !== undefined) {
      trainer.mounted = true;
      trainer.setValue = setValue;
      trainer.setMax = setMax;
      setValue(trainer.counter);
      setMax(trainer.max);
    }
    return () => {
      if (trainer !== undefined) {
        trainer.mounted = false;
      }
    };
  }, [trainer]);
  return (
    <div>
      <button
        onClick={() => {
          if (trainer?.timerID === null) {
            trainer?.train(dispatch, setValue);
          } else {
            trainer?.stop();
          }
        }}
      >
        {props.text}
      </button>
      <progress max={max} value={value} />
    </div>
  );
};
