import { Spritesheet } from "pixi.js";
import { ENTITY_STATE } from "../../../Models/Entities/State Machine/EntityStateMachine";
import { Game } from "../../GameEngine/Game";
import { EntitySprite } from "./EntitySprite";

export class PlayerSprite extends EntitySprite {
  public constructor(spriteSheet: Spritesheet, max: number, value: number) {
    super(Game.Player, spriteSheet, max, value);
    this.zIndex = 3;
  }

  public changeAnimation(state: ENTITY_STATE) {
    super.changeAnimation(state);
    this.zIndex = 3;
  }
}
