import React, { useEffect, useMemo, useState } from "react";
import {
  BattleMessage,
  BattleMessageType,
} from "../../../Core/BattleEngine/BattleMessage";
import { Battle, StateTypes } from "../../../Core/BattleEngine/Battle";

type Props = {
  battle: Battle;
};
type State = {
  battlelog: BattleMessage[];
};

export const BattleLog = (props: Props) => {
  const battle = props.battle;
  const [state, setState] = useState<State>({ battlelog: battle.battlelog });

  const messages = useMemo(() => {
    return updateMessages(state.battlelog);
  }, [state]);

  useEffect(() => {
    battle.stateManager.get(StateTypes.BATTLELOG)?.set("BattleLog", setState);
    return;
  }, [state, battle]);

  function updateMessages(log) {
    var m: JSX.Element[] = [];
    var count = 0;
    log.forEach((message: BattleMessage) => {
      switch (message.type) {
        case BattleMessageType.ATTACK:
          m.push(
            <div key={count} style={{ padding: "5px" }}>
              <span
                style={{ color: "lightgreen" }}
              >{`${message.attacker}`}</span>
              <span>{` did  `}</span>
              <span style={{ color: "crimson" }}>{`${message.damage.toFixed(
                0
              )} damage`}</span>
              <span>{` to `}</span>
              <span style={{ color: "khaki" }}>{`${message.target}`}</span>
            </div>
          );
          break;
        case BattleMessageType.KILLED:
          m.push(
            <div key={count} style={{ padding: "5px" }}>
              <span
                style={{ color: "lightgreen" }}
              >{`${message.attacker}`}</span>
              <span>{` killed  `}</span>
              <span style={{ color: "khaki" }}>{`${message.target}`}</span>
              <span>{`, received: `}</span>
              <span style={{ color: "gold" }}>{`${message.gold} gold`}</span>
              <span>{` and `}</span>
              <span
                style={{ color: "lightskyblue" }}
              >{`${message.xp} XP`}</span>
            </div>
          );
          break;
        case BattleMessageType.DIED:
          m.push(
            <div key={count} style={{ padding: "5px" }}>
              <span
                style={{ color: "lightgreen" }}
              >{`${message.attacker}`}</span>
              <span>{` killed  `}</span>
              <span style={{ color: "crimson" }}>{`${message.target}`}</span>
              <span>{`...`}</span>
            </div>
          );
          break;
        default:
          <div />;
          break;
      }

      count++;
    });
    return m.reverse();
  }

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 2,

        display: "inline-flex",
        flexDirection: "column",
        width: "400px",

        border: "2px solid  #dddddd",
        borderRadius: "2vh",

        backgroundColor: "#000000",
        opacity: 0.8,
      }}
    >
      <h4
        style={{
          borderBottom: "2px solid  #dddddd",
          paddingBottom: "1vh",
        }}
      >
        Battle log:
      </h4>
      <div
        style={{
          marginTop: "-2vh",
          overflow: "hidden",
          width: "100%",
          height: "15vh",
          textAlign: "center",
        }}
      >
        <div
          style={{
            overflowY: "scroll",
            height: "15vh",
            fontSize: "1vh",
            marginTop: "1vh",
            paddingRight: "2vw",
            paddingLeft: "2vw",
            boxSizing: "content-box",
            width: "100%",
            whiteSpace: "initial",
            marginLeft: "-2vw",
          }}
        >
          {messages}
        </div>
      </div>
    </div>
  );
};
