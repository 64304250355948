import React from "react";
import "./styles/playerstats.css";
import { useSelector } from "react-redux";
import { Player } from "../../Models/Entities/Player";
import { MainStats } from "./SubComponents/MainStats";
import { DamageStats } from "./SubComponents/DamageStats";
import { Upgrades } from "./SubComponents/Upgrades";

const PlayerStats = () => {
  const playerUpdater = useSelector((state) => state.playerUpdater);
  let player: Player = playerUpdater.payload.player;
  return (
    <div>
      <h2 style={{ margin: 0 }}>Player:</h2>
      <div
        className="playerRows"
        style={{
          alignItems: "center",
          paddingBottom: "1vh",
        }}
      >
        <div style={{ width: "20vw" }}>
          <p>Level: {player.level}</p>
          <p>
            XP: {player.xp.mag} / {player.threshold.mag}
          </p>
          <progress max={player.threshold.mag} value={player.xp.mag} />
        </div>
        <div style={{ width: "20vw" }}>
          <p style={{ color: "crimson" }}>
            Max health: {player.stats.health.max}
          </p>
          <p style={{ color: "dodgerblue" }}>
            Max mana: {player.stats.mana.max}
          </p>
          <p>Skill points: {player.skillPoints}</p>
        </div>
      </div>
      <div
        className="playerRows"
        style={{
          display: "inline-flex",
          border: "1px solid #dddddd",
          borderRadius: "1vw",
          paddingTop: "2vh",
          paddingBottom: "2vh",
        }}
      >
        <div
          style={{
            minWidth: "30vw",
            borderRight: "1px solid white",
          }}
        >
          <MainStats />
        </div>
        <div
          style={{
            minWidth: "30vw",
            borderLeft: "1px solid white",
          }}
        >
          <DamageStats />
        </div>
      </div>
      <div className="playerRows">
        <Upgrades />
      </div>
    </div>
  );
};

export default PlayerStats;
