import { DependantAttribute } from "../DependantAttribute";

export class Attack extends DependantAttribute {
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every strength = 1 attack
    var strength: number = this._otherAttributes[0].calculateValue();
    this._finalValue += strength - 1;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    return this._finalValue;
  }
}
