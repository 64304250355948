import { AnimatedSprite, Spritesheet } from "pixi.js";
import { ENTITY_STATE } from "../../../Models/Entities/State Machine/EntityStateMachine";
import { Monster } from "../../../Models/Entities/Monsters/Monster";
import { AssetLoader } from "../../../Utils/Loaders/AssetLoader";
import { EntitySprite } from "./EntitySprite";

export class MonsterSprite extends EntitySprite {
  public summonAbleEffect: AnimatedSprite;

  public constructor(
    monster: Monster,
    spriteSheet: Spritesheet,
    max: number,
    value: number
  ) {
    super(monster, spriteSheet, max, value);

    this.summonAbleEffect = new AnimatedSprite(
      AssetLoader.getInstance().spriteSheets.get("CircleEffect")?.animations[
        "Effect"
      ]
    );
    this.summonAbleEffect.tint = 0x00000;
    this.summonAbleEffect.y = this.hitBox.height - this.hitBox.width / 1.5;
    this.summonAbleEffect.width = this.hitBox.width;
    this.summonAbleEffect.height = this.hitBox.width;
    this.addChild(this.summonAbleEffect);
    this.summonAbleEffect.animationSpeed = 0.25;
    this.summonAbleEffect.play();
    this.summonAbleEffect.visible = false;
  }

  public changeAnimation(state: ENTITY_STATE) {
    if (state === ENTITY_STATE.DEAD) {
      this._sprite.animationSpeed = 0.25;
      this.zIndex = 0;
      this._sprite.textures = this._spriteSheet.animations[
        "Death"
      ] as PIXI.Texture[];
      this._sprite.loop = false;
      this._sprite.onComplete = () => {
        //this.visible = false;
        if (Math.random() <= (this.e as Monster).chance) {
          this._zIndex = 5;
          this.summonAbleEffect.visible = true;
          this._hitBox.interactive = true;
        } else {
          this._hitBox.interactive = false;
        }
      };
      this._sprite.play();
      this.updateThumbnail();
    } else {
      super.changeAnimation(state);
    }
  }
}
