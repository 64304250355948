import Forest from "./Forest/Forest.png";
import Cave from "./Cave/Cave 3.png";

interface image {
  img: string;
}

export default new Map<string, image>()
  .set("Forest", { img: Forest })
  .set("Cave", { img: Cave });
