import * as PIXI from "pixi.js";

export default class HealthBarCointainer extends PIXI.Container {
  private outer: PIXI.Graphics;
  private inner: PIXI.Graphics;

  private _max: number;
  private _value: number;

  private _barWidth: number = 40;
  private _barHeight: number = 3;

  public constructor(max: number, value: number) {
    super();
    this._max = max;
    this._value = value;

    // RED INNER PORTION OF HEALTHBAR
    // NOTICE 50px width, 5px height
    this.inner = new PIXI.Graphics();
    this.inner.beginFill(0xdc143c);
    this.inner.drawRect(0, 0, this._barWidth, this._barHeight);
    this.inner.endFill();
    this.addChild(this.inner);

    this.outer = new PIXI.Graphics();
    this.outer.beginFill(0x000000);
    const w = this._barWidth - (this._barWidth * value) / max;
    const xpos = this._barWidth - w;
    this.outer.drawRect(xpos, 0, w, this._barHeight);
    this.outer.endFill();
    this.addChild(this.outer);

    if (max === value) this.visible = false;
    else this.visible = true;
  }

  public updateHealth(value: number, max: number) {
    if (value !== this._value || max !== this._max) {
      this.outer.clear();
      this.outer.beginFill(0x000000);
      const w = this._barWidth - (this._barWidth * value) / max;
      const xpos = this._barWidth - w;
      this.outer.drawRect(xpos, 0, w, this._barHeight);
      this.outer.endFill();
    }
    if (max !== value && value > 0) this.visible = true;
    else this.visible = false;
  }

  public get barWidth(): number {
    return this._barWidth;
  }
  public set barWidth(value: number) {
    this._barWidth = value;
  }
  public get barHeight(): number {
    return this._barHeight;
  }
  public set barHeight(value: number) {
    this._barHeight = value;
  }

  public get max(): number {
    return this._max;
  }
  public set max(value: number) {
    this._max = value;
  }
  public get value(): number {
    return this._value;
  }
  public set value(value: number) {
    this._value = value;
  }
}
