import Monsters from "../../Models/Data/monsters.json";
import { Monster } from "../../Models/Entities/Monsters//Monster";
import { Stats } from "../../Models/Stats/Stats";

interface MonsterStats {
  health: number;
  mana: number;
  attack: number;
}

interface MonsterInterface {
  name: string;
  level: number;
  xp: number;
  chance: number;
  stats: MonsterStats;
  drops: any[];
  gold: number;
  sprite: string;
  summonable: boolean;
}

export class MonsterLoader {
  public static loadMonster(id: string) {
    const m: MonsterInterface = Monsters[id];
    const stats = new Stats();

    Object.keys(m.stats).forEach((s) => {
      switch (s) {
        case "health":
          stats.health.baseValue = m.stats[s];
          break;
        case "mana":
          stats.mana.baseValue = m.stats[s];
          break;
        case "attack":
          stats.attack.baseValue = m.stats[s];
          break;
        default:
          break;
      }
    });

    const monster = new Monster(
      m.name,
      m.level,
      stats,
      m.xp,
      m.chance,
      m.gold,
      m.drops,
      m.sprite
    );
    return monster;
  }
}
