import { Entity } from "./Entity";
import Decimal from "break_eternity.js";
import { Minion } from "./Summons/Minion";
import { General } from "./Summons/General";
import { updatePlayer } from "../../Utils/Store/Actions/PlayerAction";
import { Game } from "../../Core/GameEngine/Game";
import { RegenerationComponent } from "./Entity-Components/RegenerationComponent";
import { ENTITY_STATE } from "./State Machine/EntityStateMachine";

export class Player extends Entity {
  private static instance: Player;

  private _skillPoints: number = 0;

  private _xp: Decimal = new Decimal(0);
  private _threshold: Decimal = new Decimal(100);

  //protected _stats: PlayerStats;

  private _generals: General[] = [];
  private _minions: Minion[] = [];

  private _gold: number = 0;

  private healthRegen: RegenerationComponent;
  private manaRegen: RegenerationComponent;

  private constructor(name, level) {
    super("Player", name, level, "Knight");
    this.healthRegen = new RegenerationComponent(
      this,
      this._stats.health,
      this._stats.healthRegen
    );
    this.manaRegen = new RegenerationComponent(
      this,
      this._stats.mana,
      this._stats.manaRegen
    );
  }

  public static getInstance(): Player {
    if (!Player.instance) {
      Player.instance = new Player("Player", 1);
      Player.instance._xp = new Decimal(0);
      Player.instance._threshold = new Decimal(100);
      Player.instance._stats.health.baseValue = 1010;
      Player.instance._stats.health.calculateValue();
    }
    return Player.instance;
  }

  public update(delta: number) {
    super.update(delta);
    this._minions.forEach((m) => {
      if (m.stateMachine.currentState === ENTITY_STATE.DEAD) {
        m.revive();
      }
    });
  }

  public regenUpdate(delta: number) {
    this.healthRegen.update(delta);
    this.manaRegen.update(delta);
  }

  public addXp(x: number) {
    Player.instance._xp = Player.instance._xp.add(x);
    while (Player.instance.xp.gte(Player.instance._threshold)) {
      Player.instance._level++;
      Player.instance._skillPoints += 3;
      // TODO: create a threshold calculator
      Player.instance._threshold = Player.instance._threshold.mul(2);
      Player.instance._stats.health.current = Player.instance._stats.health.max;
      Player.instance._stats.mana.current = Player.instance._stats.mana.max;
    }
  }

  public addGold(gold: number) {
    if (gold < 0) {
      return;
    }
    Player.instance._gold += gold;
  }

  public addMinion(minion: Minion) {
    // REMOVE THIS LINE WHEN WE CAN HIT A SUMMON BUTTON
    this._minions.push(minion);
  }
  public summonMinion(minion: Minion): boolean {
    if (minion.summoned) return false;
    this._stats.mana.current -= minion.manaCost;
    Game.dispatch(updatePlayer(this));
    return true;
  }
  public dismissMinion(minion: Minion): boolean {
    if (!minion.summoned) return false;
    return true;
  }

  public get xp(): Decimal {
    return this._xp;
  }

  public get minions(): Minion[] {
    return this._minions;
  }

  public get generals(): General[] {
    return this._generals;
  }

  public get skillPoints(): number {
    return this._skillPoints;
  }
  public set skillPoints(value: number) {
    this._skillPoints = value;
  }

  public get threshold(): Decimal {
    return this._threshold;
  }
  public get gold(): number {
    return this._gold;
  }
}
