import { Page, PageManager } from "../../../Utils/Managers/PageManager";
import { Tick } from "../../../Utils/Tick";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";
import { Player } from "../../../Models/Entities/Player";
import { STAT } from "../../PlayerStats/SubComponents/MainStats";

export class Trainer {
  private _timerID;

  private level: number = 1;
  private _counter: number = 0;

  private _stat: STAT;

  private _max: number = 100;

  private _mounted: boolean;
  private _setMax;
  private _setValue;
  private _dispatch;

  public constructor(stat: STAT) {
    this._stat = stat;
    this._timerID = null;
    this._mounted = true;
  }

  public train(dispatch: any, setValue) {
    if (this.timerID === null) {
      this.dispatch = dispatch;
      this.setValue = setValue;
      this._timerID = setInterval(() => this.tick(), Tick.rate);
    }
  }
  // Ticks
  private tick() {
    let player = Player.getInstance();
    // Checks if reached max
    if (this.counter === this.max) {
      switch (this.stat) {
        case STAT.STRENGTH:
          player.stats.strength.baseValue++;
          break;
        case STAT.DEXTERITY:
          player.stats.dexterity.baseValue++;
          break;
        case STAT.INTELLIGENCE:
          player.stats.intelligence.baseValue++;
          break;
        default:
          player.stats.vitality.baseValue++;
          break;
      }

      // If we see the player page
      if (PageManager.getInstance().currentPage === Page.Player) {
        this.dispatch(updatePlayer(Player.getInstance()));
      }

      // Reset counter, add level and create new max
      this._counter = 0;
      this.level++;
      this.max = this.level * 100;

      // Tell new max to component
      if (this.mounted) {
        this.setMax(this.max);
      }
    } else {
      // Increment counter
      this._counter++;
    }
    // If mounted, set new value to progress bar
    if (this.mounted) {
      this.setValue(this.counter);
    }
  }

  // Stop ticker
  public stop() {
    clearInterval(this.timerID);
    this._timerID = null;
  }
  public set mounted(value: boolean) {
    this._mounted = value;
  }
  public get mounted() {
    return this._mounted;
  }
  public get max(): number {
    return this._max;
  }
  public set max(value: number) {
    this._max = value;
  }
  public get counter() {
    return this._counter;
  }
  public set setValue(value) {
    this._setValue = value;
  }
  public get setValue() {
    return this._setValue;
  }
  public set dispatch(value) {
    this._dispatch = value;
  }
  public get dispatch() {
    return this._dispatch;
  }
  public get setMax() {
    return this._setMax;
  }
  public set setMax(value) {
    this._setMax = value;
  }
  public get timerID() {
    return this._timerID;
  }
  public get stat(): STAT {
    return this._stat;
  }
  public set stat(value: STAT) {
    this._stat = value;
  }
}
