import { closeToAttack } from "../../../Utils/Calculation";
import { Entity } from "../Entity";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";

export class TargetingComponent
  extends Component
  implements ComponentInterface {
  private searchTimer: number = 0;
  private searchCooldown: number = 500;

  private lastUpdate: number = 0;

  public update(delta: number) {
    const now = performance.now();
    this.searchTimer -= now - this.lastUpdate;
    this.lastUpdate = now;
    if (
      this.e.stateMachine.currentState === ENTITY_STATE.MOVE ||
      this.e.stateMachine.currentState === ENTITY_STATE.SEARCH
    ) {
      //* Check if target is dead
      if (
        (this.e.target && this.e.target.stats.health.current <= 0) ||
        this.e.stateMachine.previousState === ENTITY_STATE.REANIMATE
      ) {
        this.searchTimer = this.searchCooldown;
        this.findClosestTarget();
        //! IF TARGET IS UNDEFINED
        if (!this.e.target) {
          this.e.stateMachine.nextState = ENTITY_STATE.WAIT;
          this.e.animation?.stateQueue.push(ENTITY_STATE.WAIT);
        } else {
          if (closeToAttack(this.e, this.e.target)) {
            this.e.stateMachine.nextState = ENTITY_STATE.COMBAT;
          } else {
            this.e.stateMachine.nextState = ENTITY_STATE.MOVE;
          }
        }
        return;
      }

      //* Check if cooldown is over
      if (this.searchTimer <= 0) {
        this.searchTimer = this.searchCooldown;
        this.findClosestTarget();
        //! IF TARGET IS UNDEFINED
        if (!this.e.target) {
          this.e.stateMachine.nextState = ENTITY_STATE.WAIT;
          this.e.animation?.stateQueue.push(ENTITY_STATE.WAIT);
        } else {
          if (closeToAttack(this.e, this.e.target)) {
            this.e.stateMachine.nextState = ENTITY_STATE.COMBAT;
          } else {
            this.e.stateMachine.nextState = ENTITY_STATE.MOVE;
          }
        }
        return;
      }
    }
  }

  private findClosestTarget() {
    let enemies;

    //! NEED TO UPDATE, A LOT OF UNNESSECERY CALCULATION HERE
    // TODO: ONLY CHECKS IF THEY ARE MONSTER OR PLAYER, NEED FOR MINIONS
    if (this.e.className === "Monster") {
      enemies = this.e.assignedBattle?.battleObjects.filter(
        (x) => !(x.className === "Monster")
      );
    } else {
      enemies = this.e.assignedBattle?.battleObjects.filter(
        (x) => !(x.className === "Player") && !(x.className === "Minion")
      );
    }
    enemies = enemies.filter(
      (x: Entity) => x.stateMachine.currentState !== ENTITY_STATE.DEAD
    );

    if (enemies.length === 0) {
      this.e.target = undefined;
      this.e.stateMachine.currentState = ENTITY_STATE.WAIT;
      return;
    }
    const x = this.e.centerPosition.x;
    const y = this.e.centerPosition.y;

    var target: Entity | undefined = undefined;
    var dist = Infinity;

    enemies.forEach((enemy: Entity) => {
      const xt = enemy.centerPosition.x;
      const yt = enemy.centerPosition.y;

      const a = xt - x;
      const b = yt - y;

      const tempDist = Math.sqrt(a * a + b * b);

      if (tempDist < dist) {
        target = enemy;
        dist = tempDist;
      }
    });

    this.e.target = target;
  }
}
