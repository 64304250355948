import { Attribute } from "./Attribute";

export class DependantAttribute extends Attribute {
  protected _otherAttributes: Attribute[] = [];

  public constructor(startValue: number) {
    super(startValue);
  }

  public addAttribute(attr: Attribute) {
    this._otherAttributes.push(attr);
    attr.addDependent(this);
  }

  public removeAttribute(attr: Attribute): void {
    if (this._otherAttributes.indexOf(attr) >= 0) {
      this._otherAttributes.splice(this._otherAttributes.indexOf(attr), 1);
    }
    if (attr.dependentAttributes.indexOf(this) >= 0) {
      attr.dependentAttributes.splice(this._otherAttributes.indexOf(this), 1);
    }
  }

  public calculateValue(): number {
    // Specific attribute code goes somewhere in here
    this._finalValue = this.baseValue;
    this.applyRawBonuses();
    this.applyFinalBonuses();
    return this._finalValue;
  }

  public get baseValue(): number {
    return this._baseValue;
  }
  public set baseValue(value: number) {
    this._baseValue = value;
    this.calculateValue();
    this._dependentAttributes.forEach((a) => {
      a.calculateValue();
    });
  }
}
