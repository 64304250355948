import React, { useEffect, useState } from "react";
import { Battle } from "../../Core/BattleEngine/Battle";
import { PixiContainer } from "./PixiContainer/PixiContainer";
import { DungeonLoader } from "../../Utils/Loaders/DungeonLoader";
import { BATTLE_TYPE, Game } from "../../Core/GameEngine/Game";
import { BattleLog } from "./SubComponents/BattleLog";
import { EntityBox } from "./SubComponents/EntityBox";

const Dungeon = (props) => {
  const [battle] = useState<Battle>(() => initBattle());

  useEffect(() => {
    battle.mounted = true;
    return () => {
      battle.mounted = false;
    };
  }, [battle]);

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
        }}
      >
        <button
          style={{ left: 0, position: "absolute" }}
          onClick={() => {
            props.back();
          }}
        >
          Back
        </button>
        <h2 style={{ margin: 0, display: "inline-block" }}>{props.id}</h2>
        <button
          style={{ right: 0, position: "absolute" }}
          onClick={() => {
            battle.startAttack();
          }}
        >
          Attack
        </button>
      </div>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <PixiContainer battle={battle} />
        <BattleLog battle={battle} />
        <EntityBox battle={battle} />
      </div>
    </React.Fragment>
  );

  function initBattle(): Battle {
    var b = Game.getBattle(BATTLE_TYPE.DUNGEON, props.type);
    if (!b) {
      b = DungeonLoader.loadDungeon(props.id);
      Game.addBattle(BATTLE_TYPE.DUNGEON, b, props.type);
    }
    b.mounted = true;
    return b;
  }
};

export default Dungeon;
