import { Battle } from "../BattleEngine/Battle";

import { wrap } from "comlink";
import { Player } from "../../Models/Entities/Player";
import { AssetLoader } from "../../Utils/Loaders/AssetLoader";

export enum BATTLE_TYPE {
  DUNGEON = "DUNGEON",
}

export enum DUNGEON_TYPE {
  FOREST,
  CAVE,
  NONE,
}

export class Game {
  //! Instance to make it a singleton. Acessed everywhere
  private static _instance: Game;
  //* The id to start and clear the logic loop intervall
  private gameloopId;
  private static lastUpdate: number = 0;
  private static delta: number = 0;
  //* 30 updates per second
  private tickRate: number = 1000 / 30;
  //! TESTING WORKER
  private worker;

  private static player: Player;
  public static get Player() {
    return Game.player;
  }

  //* Dispatcher
  public static dispatch;

  //* Global variables:

  //* To know what battles to update
  private static onBattles: Map<BATTLE_TYPE, Map<any, Battle>> = new Map<
    BATTLE_TYPE,
    Map<any, Battle>
  >();
  private static offBattles: Map<BATTLE_TYPE, Map<any, Battle>> = new Map<
    BATTLE_TYPE,
    Map<any, Battle>
  >();

  public static get instance() {
    if (!Game._instance) {
      Game._instance = new Game();
      AssetLoader.getInstance().loadAsset();

      for (const bt in BATTLE_TYPE) {
        this.onBattles.set(bt as BATTLE_TYPE, new Map<any, Battle>());
        this.offBattles.set(bt as BATTLE_TYPE, new Map<any, Battle>());
      }
    }
    return Game._instance;
  }

  private gameloop() {
    console.log("Gameloop ran!");
    const now = performance.now();
    Game.delta = now - Game.lastUpdate;

    Game.player.regenUpdate(Game.delta);
    Game.onBattles.forEach((m) => {
      m.forEach((b) => {
        b.update(Game.delta);
      });
    });

    Game.lastUpdate = now;
  }

  public start() {
    this.gameloopId = setInterval(this.gameloop, this.tickRate);
  }

  public stop() {
    clearInterval(this.gameloopId);
    this.gameloopId = null;
  }

  private async runWorker() {
    const worker = new Worker("../../Utils/Workers", {
      name: "my-first-worker",
      type: "module",
    });
    const workerApi = wrap<import("../../Utils/Workers").MyFirstWorker>(worker);
    const result = await workerApi.takeALongTimeToDoSomething(
      Player.getInstance()
    );
    Player.getInstance().position = result;
    console.log("Result!:");
    console.log(Player.getInstance());
  }

  public loadPlayer() {
    Game.player = Player.getInstance();
    Game.player.loadSprite();
  }

  public static addBattle(type: BATTLE_TYPE, battle: Battle, keyInType: any) {
    Game.onBattles.get(type)?.set(keyInType, battle);
  }
  public static getBattle(type: BATTLE_TYPE, keyInType: any) {
    return Game.onBattles.get(type)?.get(keyInType);
  }
}
