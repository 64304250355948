import React from "react";
import "./styles/upgrades.css";
export const Upgrades = () => {
  const UpgradeRow = (props) => {
    let boxes = [<div></div>];
    boxes.pop()
    for (var i = 0; i < props.length; i++) {
      let key = props.row.toString() + ":" + (i+1).toString();
      let box = <div key={key} className="upgradesBox" onClick={() => {console.log(key)}}></div>;
      boxes.push(box)
    }
    return <div key={"row:"+props.row.toString()} className="upgradesRow"> {boxes}</div>;
  }

  return (
    <div>
      <h3>Upgrades:</h3>
      <div
        style={{
          height: "auto",
          width: "auto",
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "1px solid #dddddd",
        }}
      >
        <UpgradeRow row = {1} length={24}/>
        <UpgradeRow row = {2} length={23}/>
        <UpgradeRow row = {3} length={22}/>
        <UpgradeRow row = {4} length={21}/>

      </div>
      <div
        style={{
          height: "auto",
          width: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop: "1vh",
        }}
      >
        <figure
          style={{
            textAlign: "center",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="upgradesBox"
            style={{ backgroundColor: "transparent" }}
          ></div>
          <figcaption>_Unselected_</figcaption>
        </figure>
        <figure
          style={{
            textAlign: "center",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="upgradesBox"
            style={{ backgroundColor: "forestgreen" }}
          ></div>
          <figcaption>_Done_</figcaption>
        </figure>
        <figure
          style={{
            textAlign: "center",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="upgradesBox"
            style={{ backgroundColor: "rebeccapurple" }}
          ></div>
          <figcaption>_Special_</figcaption>
        </figure>
        <figure
          style={{
            textAlign: "center",
            margin: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="upgradesBox"
            style={{ backgroundColor: "crimson" }}
          ></div>
          <figcaption>_Functional_</figcaption>
        </figure>
      </div>
    </div>
  );
};
