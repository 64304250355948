import { Action } from "./Action";

export class ActionResult {
  private log: string = "";
  private nextAction: Action | undefined;

  constructor(action?: Action, log: string = "") {
    this.log = log;
    this.nextAction = action;
  }

  public print() {
    console.log(this.log);
  }
}
