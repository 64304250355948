import { Stats } from "../../Stats/Stats";
import { Summon } from "./Summon";

export class Minion extends Summon {
  constructor(
    name: string = "",
    level: number = 1,
    stats: Stats = new Stats(),
    manaCost: number = 10,
    rank: string = "Standard",
    sprite: string
  ) {
    super("Minion", name, level, stats, manaCost, rank, sprite);
  }
}
