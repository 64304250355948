import { DependantAttribute } from "../DependantAttribute";

export class HealthRegen extends DependantAttribute {
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every vitality point, increases health regen with 0.1
    var vitality: number = this._otherAttributes[0].calculateValue();
    this._finalValue += (vitality - 1) * 0.1;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    return this._finalValue;
  }
}
