import { BaseAttribute } from "./BaseAttribute";
import { FinalBonus } from "./FinalBonus";
import { RawBonus } from "./RawBonus";

export class Attribute extends BaseAttribute {
  private _rawBonuses: RawBonus[] = [];
  private _finalBonuses: FinalBonus[] = [];

  protected _finalValue: number;

  public constructor(startValue: number) {
    super(startValue);
    this._finalValue = this.baseValue;
  }

  public addRawBonus(bonus: RawBonus) {
    this._rawBonuses.push(bonus);
  }
  public addFinalBonus(bonus: FinalBonus) {
    this._finalBonuses.push(bonus);
  }
  public removeRawBonus(bonus: RawBonus) {
    if (this._rawBonuses.indexOf(bonus) >= 0)
      this._rawBonuses.splice(this._rawBonuses.indexOf(bonus), 1);
  }
  public removeFinalBonus(bonus: FinalBonus) {
    if (this._rawBonuses.indexOf(bonus) >= 0)
      this._rawBonuses.splice(this._rawBonuses.indexOf(bonus), 1);
  }

  protected applyRawBonuses() {
    // Adding value from raw
    var rawBonusValue: number = 0;
    var rawBonusMultiplier: number = 0;

    this._rawBonuses.forEach((bonus) => {
      rawBonusValue += bonus.baseValue;
      rawBonusMultiplier += bonus.baseMultiplier;
    });

    this._finalValue += rawBonusValue;
    this._finalValue *= 1 + rawBonusMultiplier;
  }

  protected applyFinalBonuses(): void {
    // Adding value from final
    var finalBonusValue: number = 0;
    var finalBonusMultiplier: number = 0;

    this._finalBonuses.forEach((bonus) => {
      finalBonusValue += bonus.baseValue;
      finalBonusMultiplier += bonus.baseMultiplier;
    });

    this._finalValue += finalBonusValue;
    this._finalValue *= 1 + finalBonusMultiplier;
  }

  public calculateValue(): number {
    this._finalValue = this.baseValue;
    this.applyRawBonuses();
    this.applyFinalBonuses();
    return this._finalValue;
  }

  public get finalValue(): number {
    return this.calculateValue();
  }
}
