import { Reducer } from "redux";
import { Page } from "../../Managers/PageManager";
import { CHANGE_PAGE, NavigationAction } from "../Actions/NavigationAction";

interface Navigation {
  page: Page;
}

const defaultState: Navigation = {
  page: Page.Player,
};

export const navigation: Reducer<Navigation, NavigationAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return { page: action.page };
    default:
      return state;
  }
};
