import React from "react";
import "./App.css";
import { Menu } from "./Components/Common/Menu";
import { Main } from "./Pages/Main";

const App = () => {
  return (
    <div
      className="App"
      style={{
        backgroundColor: "#242424",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Menu style={{ border: "2px solid #dddddd", width: "14%" }} />
        <Main
          style={{
            display: "flex",
            flexDirection: "column",
            borderTop: "2px solid #dddddd",
            borderRight: "2px solid #dddddd",
            borderBottom: "2px solid #dddddd",
            width: "86%",
          }}
        />
      </div>
    </div>
  );
};

export default App;
