import { DependantAttribute } from "../DependantAttribute";

export class ManaRegen extends DependantAttribute {
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every intelligence point, increases mana regen with 0.1
    var intelligence: number = this._otherAttributes[0].calculateValue();
    this._finalValue += (intelligence - 1) * 0.1;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    return this._finalValue;
  }
}
