import React from "react";
import { StatusBar } from "./StatusBar";

export const Header = (props) => {
  return (
    <div style={props.style}>
      <h1>Arise</h1>
      <div
        style={{
          position: "absolute",
          right: "0",
        }}
      >
        <StatusBar />
      </div>
    </div>
  );
};
