import { DependantAttribute } from "../DependantAttribute";

export class Mana extends DependantAttribute {
  private _max: number;
  private _current: number;

  constructor(startValue: number) {
    super(startValue);
    this._max = startValue;
    this._current = startValue;
  }
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every intelligence point, increases mana with 10 points
    let intelligence: number = this._otherAttributes[0].calculateValue();
    this._finalValue += (intelligence - 1) * 10;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    this._current += this._finalValue - this._max;

    this._max = this._finalValue;

    return this._finalValue;
  }

  public get current(): number {
    return this._current;
  }
  public set current(value: number) {
    this._current = value;
    if (this._current > this._max) this._current = this._max;
    else if (this._current < 0) this._current = 0;
  }
  public get max(): number {
    return this._max;
  }
}
