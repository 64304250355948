export class Tick {
  private static _rate: number = 50;

  private static _clockRate: number = 1000;

  private static instance: Tick;

  private constructor() {}

  public static getInstance(): Tick {
    if (!Tick.instance) {
      Tick.instance = new Tick();
    }
    return Tick.instance;
  }

  public static get rate(): number {
    return Tick._rate;
  }
  public static get clockRate(): number {
    return Tick._clockRate;
  }
}
