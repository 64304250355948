import { DependantAttribute } from "../DependantAttribute";

export class AttackSpeed extends DependantAttribute {
  public calculateValue(): number {
    this._finalValue = this.baseValue;

    //? Every 5 dexterity, increases attack speed with 0.10
    var dexterity: number = this._otherAttributes[0].calculateValue();
    this._finalValue += Math.floor(dexterity / 5) / 10;

    this.applyRawBonuses();
    this.applyFinalBonuses();

    return this._finalValue;
  }
}
