import { Attack } from "./Attack";
import { Defense } from "./Defense";
import { Use } from "./Use";

export abstract class Item {
  private _name: string = "";
  private _amount: number = 1;

  private _description: string = "";
  private _requirements: string = "";

  //! WIP
  private melee: Attack = new Attack();
  private range: Attack = new Attack();
  private defense: Defense = new Defense();
  private use: Use = new Use();

  constructor(
    name: string,
    description: string,
    amount: number,
    requirements?: string
  ) {
    this._name = name;
    this._description = description;
    this._amount = amount;
    if (requirements) this._requirements = requirements;
  }
  public get name(): string {
    return this._name;
  }
  public get amount(): number {
    return this._amount;
  }
  public get description(): string {
    return this._description;
  }
  public get requirements(): string {
    return this._requirements;
  }
}

export class Gold extends Item {
  constructor(amount: number) {
    super("Gold", "Money, duh", amount);
  }
}
