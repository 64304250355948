import { Entity } from "../Entity";
import Decimal from "break_eternity.js/break_eternity";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";
import { Game } from "../../../Core/GameEngine/Game";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Stats } from "../../Stats/Stats";
import { SummonRegenerationComponent } from "../Entity-Components/SummonRegenerationComponent";

export abstract class Summon extends Entity {
  private static startId: number = 1000;
  private _id: number;
  private rank: string;

  private _manaCost: number;
  private _summoned: boolean = false;

  private _xp: Decimal = new Decimal(0);
  private _threshold: Decimal = new Decimal(100);

  private healthRegen: SummonRegenerationComponent;

  constructor(
    className: string,
    name: string = "",
    level: number = 1,
    stats: Stats = new Stats(),
    manaCost: number = 10,
    rank: string = "Standard",
    sprite: string
  ) {
    super(className, name, level, sprite);
    this._manaCost = manaCost;
    this._id = Summon.startId;
    this.rank = rank;
    this._stats = stats;
    Summon.startId++;

    this.healthRegen = new SummonRegenerationComponent(
      this,
      this._stats.health,
      this._stats.healthRegen
    );
  }

  public get id(): number {
    return this._id;
  }
  public get summoned(): boolean {
    return this._summoned;
  }
  public set spawned(value: boolean) {
    this._summoned = value;
  }
  public get manaCost(): number {
    return this._manaCost;
  }

  public get xp(): Decimal {
    return this._xp;
  }
  public get threshold(): Decimal {
    return this._threshold;
  }

  public update(delta: number) {
    super.update(delta);
    this.healthRegen.update(delta);
  }

  public revive() {
    if (Game.Player.stats.mana.current >= this._manaCost) {
      this.stateMachine.nextState = ENTITY_STATE.REANIMATE;
      Game.Player.stats.mana.current -= this._manaCost;
      Game.dispatch(updatePlayer(Game.Player));
    }
  }

  public addXp(x: number) {
    this._xp = this._xp.add(x);
    while (this._xp.gte(this._threshold)) {
      this._level++;
      this._stats.attack.baseValue += 1;
      this._threshold = this._threshold.mul(2);
    }
  }
}
