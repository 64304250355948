import { Player } from "../../../Models/Entities/Player";

import React from "react";
export const DamageStats = () => {
  let player: Player = Player.getInstance();
  return (
    <div>
      <h3 style={{ marginTop: "0px", paddingRight: "1vw" }}>Damage stats:</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ marginRight: "2vw" }}>
          <p>Attack:</p>
          <p>Attack speed:</p>
          <p>Crit chance:</p>
          <p>Crit multiplier:</p>
          <p>Movement speed:</p>
        </div>
        <div
          style={{
            marginRight: "0vw",
            textAlign: "initial",
            whiteSpace: "nowrap",
          }}
        >
          <p>{player.stats.attack.finalValue}</p>
          <p>{player.stats.attackSpeed.finalValue.toFixed(2)}</p>
          <p>{player.stats.critChance.finalValue.toFixed(2)} %</p>
          <p>{player.stats.critMultiplier.finalValue.toFixed(2)}</p>
          <p>{player.stats.movementSpeed.finalValue.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};
