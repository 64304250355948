export class Defense {
  private armor: number = 0;
  private dodge: number = 0;

  constructor(armor: number = 0, dodge: number = 0) {
    this.armor = armor;
    this.dodge = dodge;
  }

  public defence() {
    return this.armor;
  }
}
