import { StateMachine } from "./StateMachine";

export enum ENTITY_STATE {
  WAIT,
  SEARCH,
  MOVE,
  ATTACK,
  ATTACKCD,
  DEAD,
  REANIMATE,
  COMBAT,
}

export class EntityStateMachine extends StateMachine {
  public previousState?: ENTITY_STATE = undefined;
  public currentState: ENTITY_STATE = ENTITY_STATE.WAIT;
  private _nextState?: ENTITY_STATE | undefined = undefined;

  public get nextState(): ENTITY_STATE | undefined {
    return this._nextState;
  }

  //! CHANGING NEXT STATE ONLY IF NEXT STATE IS NOT SET
  public set nextState(value: ENTITY_STATE | undefined) {
    if (!value) {
      this._nextState = value;
      return;
    }
    if (!this._nextState) this._nextState = value;
  }

  //! SPECIAL OVERRIDE METHOD
  public overrideNext(value: ENTITY_STATE | undefined) {
    this._nextState = value;
  }
}
