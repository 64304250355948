import { Entity } from "../Entity";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";

export class PathfindingComponent
  extends Component
  implements ComponentInterface {
  public update(delta: number) {
    if (this.e.stateMachine.currentState === ENTITY_STATE.MOVE) {
      if (
        this.e.stateMachine.previousState !== ENTITY_STATE.MOVE &&
        this.e.stateMachine.currentState === ENTITY_STATE.MOVE
      ) {
        this.e.animation?.stateQueue.push(ENTITY_STATE.MOVE);
      }
      this.CalcVelocity();
    }
  }

  private CalcVelocity() {
    if (this.e.target && this.e.assignedBattle) {
      const vToTarget = this.vToTarget();
      let sur: boolean = false;

      let sepV = this.separate(
        this.e.assignedBattle.battleObjects.filter(
          (x) =>
            x !== this.e && x.stateMachine.currentState !== ENTITY_STATE.DEAD
        )
      );

      const sDx = vToTarget.dx - sepV.dx;
      const sDy = vToTarget.dy - sepV.dy;

      const testStuck = Math.sqrt(sDx * sDx + sDy * sDy);
      if (testStuck < 0.6) {
        sur = true;
      }

      if (sur) {
        this.e.velocity = {
          dx: vToTarget.dy,
          dy: -vToTarget.dx,
        };
        return;
      }

      const nDx = vToTarget.dx - sepV.dx;
      const nDy = vToTarget.dy - sepV.dy;

      const sumDXY = Math.sqrt(nDx * nDx + nDy * nDy);

      this.e.velocity = {
        dx: nDx / sumDXY,
        dy: nDy / sumDXY,
      };
      return;

      // they are going the opposite direction
      // FIND A WAHY TO POSITION BEHIND THE TARGET OF YOUR TARGET
    } else {
      this.e.velocity = {
        dx: 0,
        dy: 0,
      };
    }
  }

  private vToTarget() {
    const a = this.e.target!.centerPosition.x - this.e.centerPosition.x;
    const b = this.e.target!.centerPosition.y - this.e.centerPosition.y;

    const dist = Math.sqrt(a * a + b * b);

    return {
      dx: a / dist,
      dy: b / dist,
    };
  }

  private separate(others: Entity[]) {
    let separateVector: { dx: number; dy: number } = { dx: 0, dy: 0 };
    let count = 1;

    let avoidAllies: Entity[] = [];

    if (this.e.className !== "Monster") {
      avoidAllies = others.filter((t) => t.className !== "Monster");
    } else {
      avoidAllies = others.filter((t) => t.className === "Monster");
    }

    avoidAllies.forEach((y) => {
      const a = y.centerPosition.x - this.e.centerPosition.x;
      const b = y.centerPosition.y - this.e.centerPosition.y;

      const dist = Math.sqrt(a * a + b * b);

      if (dist < this.e.radius() * 1.25 || dist < y.radius() * 1.25) {
        separateVector = {
          dx: separateVector.dx + a / dist,
          dy: separateVector.dx + b / dist,
        };
        count++;
      }
    });
    separateVector = {
      dx: separateVector.dx / count,
      dy: separateVector.dy / count,
    };
    return separateVector;
  }
}
