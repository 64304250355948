import { Spritesheet } from "pixi.js";
import { ENTITY_STATE } from "../../../Models/Entities/State Machine/EntityStateMachine";
import { Summon } from "../../../Models/Entities/Summons/Summon";
import { EntitySprite } from "./EntitySprite";

export class SummonSprite extends EntitySprite {
  public constructor(
    summon: Summon,
    spriteSheet: Spritesheet,
    max: number,
    value: number
  ) {
    super(summon, spriteSheet, max, value);
  }

  public changeAnimation(state: ENTITY_STATE) {
    if (state === ENTITY_STATE.REANIMATE) {
      this.zIndex = 2;
      this._sprite.textures = this._spriteSheet.animations["Reanimate"];
      this._sprite.animationSpeed = 0.1;
      this._sprite.loop = false;
      this._waitingForAnimation = true;
      this._sprite.onComplete = () => {
        this._waitingForAnimation = false;
        this._hitBox.interactive = true;
        this.e.stats.health.current = this.e.stats.health.max;
        this.updateHealthBar();
        this.e.stateMachine.nextState = ENTITY_STATE.SEARCH;
      };
      this._sprite.play();
      this.updateThumbnail();
    } else {
      super.changeAnimation(state);
    }
  }
}
