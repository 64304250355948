import React, { useRef, useEffect } from "react";
import { RENDER_STATE } from "../../../Core/PixiJS/GameController";
import { Battle } from "../../../Core/BattleEngine/Battle";

type Props = {
  battle: Battle;
};

export const PixiContainer = (props: Props) => {
  const ref = useRef(document.createElement("div"));

  useEffect(() => {
    function handleResize() {
      console.log(
        "resized to: ",
        ref.current.clientWidth,
        "x",
        ref.current.clientHeight
      );
      props.battle.gui.renderer.resize(
        ref.current.clientWidth,
        ref.current.clientHeight
      );
      props.battle.gui.viewport.screenWidth = ref.current.clientWidth;
      props.battle.gui.viewport.screenHeight = ref.current.clientHeight;

      props.battle.gui.addBackgroundToThumbnail();
    }

    console.log("Width: " + ref.current.clientWidth);
    console.log("Height: " + ref.current.clientHeight);
    console.log(window.devicePixelRatio);
    props.battle.gui.renderer.resize(
      ref.current.clientWidth,
      ref.current.clientHeight
    );
    props.battle.gui.viewport.screenWidth = ref.current.clientWidth;
    props.battle.gui.viewport.screenHeight = ref.current.clientHeight;
    props.battle.gui.viewport.setZoom(0);

    props.battle.gui.start();
    ref.current.appendChild(props.battle.gui.renderer.view);

    window.addEventListener("resize", handleResize);
    return () => {
      props.battle.gui.changeState(RENDER_STATE.STOP);
    };
  }, [props.battle.gui]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        width: "100%",
        height: "100%",
        zIndex: 1,
      }}
      ref={ref}
    />
  );
};
