import { Cooldown } from "../Cooldown";

export enum COOLDOWN_TYPE {
  SPAWN,
  SPELL,
}

export class CooldownManager {
  private static instance: CooldownManager;
  private _map = new Map<COOLDOWN_TYPE, Cooldown>();

  private constructor() {}

  public static getInstance() {
    if (!CooldownManager.instance) {
      CooldownManager.instance = new CooldownManager();
    }
    return CooldownManager.instance;
  }

  public get map() {
    return this._map;
  }
}
