import { PlayerAction, UPDATE_PLAYER } from "../Actions/PlayerAction";
import { Player } from "../../../Models/Entities/Player";
import { Reducer } from "redux";

interface PlayerUpdater {
  payload: { player: Player };
}

const defaultPlayer: PlayerUpdater = {
  payload: { player: Player.getInstance() },
};

export const playerUpdater: Reducer<PlayerUpdater, PlayerAction> = (
  state = defaultPlayer,
  action
) => {
  switch (action.type) {
    case UPDATE_PLAYER:
      return action;
    default:
      return state;
  }
};
