import React, { useState } from "react";
import "./styles/menu.css";
import { useDispatch } from "react-redux";
import { changePage } from "../../Utils/Store/Actions/NavigationAction";
import { Page } from "../../Utils/Managers/PageManager";

export const Menu = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(Page.Player);

  return (
    <div style={props.style}>
      <div
        style={{
          borderBottom: "2px solid #dddddd",
          height: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "50%",
          }}
          src={process.env.PUBLIC_URL + "/logo512.png"}
          alt="Logo"
        />
      </div>
      <div
        className={Page.Player === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Player));
          setPage(Page.Player);
        }}
      >
        PLAYER
      </div>
      <div
        className={Page.Inventory === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Inventory));
          setPage(Page.Inventory);
        }}
      >
        INVENTORY
      </div>
      <div
        className={Page.Train === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Train));
          setPage(Page.Train);
        }}
      >
        TRAIN
      </div>
      <div
        className={Page.Dungeon === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Dungeon));
          setPage(Page.Dungeon);
        }}
      >
        DUNGEON
      </div>
      <div
        className={Page.Shop === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Shop));
          setPage(Page.Shop);
        }}
      >
        SHOP
      </div>
      <div
        className={Page.Settings === page ? "selectedMenu" : "menuElement"}
        onClick={() => {
          dispatch(changePage(Page.Settings));
          setPage(Page.Settings);
        }}
      >
        SETTINGS
      </div>
    </div>
  );
};
