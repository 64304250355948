import reKnight_png from "./Knight/ReKnight.png";
import knight_png from "./Knight/Knight.png";
import knight_json from "./Knight/Knight.json";

import axe_demon_png from "./Axe demon/Axe Demon.png";
import axe_demon_json from "./Axe demon/Axe Demon.json";

import red_imp_png from "./Red Imp/Red Imp.png";
import red_imp_json from "./Red Imp/Red Imp.json";

import sprout_png from "./Sprout/Sprout.png";
import sprout_json from "./Sprout/Sprout.json";

import skeleton_seeker_png from "./Skeleton Seeker/Skeleton Seeker.png";
import skeleton_seeker_json from "./Skeleton Seeker/Skeleton Seeker.json";

import huntress_png from "./Huntress/Huntress.png";
import huntress_json from "./Huntress/Huntress.json";

import cobra_png from "./Cobra/Cobra.png";
import cobra_json from "./Cobra/Cobra.json";

import circle_png from "../Effects/CircleEffect.png";
import circle_json from "../Effects/CircleEffect.json";

import fireWorm_png from "../Sprites/Fire Worm/Fire Worm.png";
import fireWorm_json from "../Sprites/Fire Worm/Fire Worm.json";

interface image {
  img: string;
  json: any;
}

export default new Map<string, image>()
  .set("Knight", { img: knight_png, json: knight_json })
  .set("ReKnight", { img: reKnight_png, json: knight_json })
  .set("RedImp", { img: red_imp_png, json: red_imp_json })
  .set("AxeDemon", { img: axe_demon_png, json: axe_demon_json })
  .set("Sprout", { img: sprout_png, json: sprout_json })
  .set("SkeletonSeeker", {
    img: skeleton_seeker_png,
    json: skeleton_seeker_json,
  })
  .set("Huntress", { img: huntress_png, json: huntress_json })
  .set("Cobra", { img: cobra_png, json: cobra_json })
  .set("FireWorm", { img: fireWorm_png, json: fireWorm_json })
  .set("CircleEffect", { img: circle_png, json: circle_json });
