import { Entity } from "../../Models/Entities/Entity";
import { BattleController } from "../PixiJS/Stages/BattleController";
import {
  CooldownManager,
  COOLDOWN_TYPE,
} from "../../Utils/Managers/CooldownManager";
import { Player } from "../../Models/Entities/Player";
import { Cooldown } from "../../Utils/Cooldown";
import { Monster } from "../../Models/Entities/Monsters//Monster";
import { BattleMessage } from "./BattleMessage";
import { randomInt } from "../../Utils/Calculation";
import { GameController } from "../PixiJS/GameController";
import { MonsterLoader } from "../../Utils/Loaders/MonsterLoader";
import { MonsterSprite } from "../PixiJS/Entities/MonsterSprite";
import { DungeonLoader } from "../../Utils/Loaders/DungeonLoader";
import { Summon } from "../../Models/Entities/Summons/Summon";
import { Minion } from "../../Models/Entities/Summons/Minion";
import { DIRECTION } from "../../Models/Entities/Entity-Components/PhysicsComponent";
import { ENTITY_STATE } from "../../Models/Entities/State Machine/EntityStateMachine";

export enum StateTypes {
  MONSTER = "MONSTER",
  MINION = "MINION",
  MINIONLIST = "MINIONLIST",
  BATTLELOG = "BATTLELOG",
  SUMMON = "SUMMON",
  BATTLETIMER = "BATTLETIMER",
  ENTITY = "ENTITY",
}

export class Battle {
  private id: string;
  private player: Player = Player.getInstance();
  private focusedEntity!: Entity;
  private _battleObjects: (Monster | Player | Summon)[] = [];
  public get battleObjects(): (Monster | Player | Summon)[] {
    return this._battleObjects;
  }

  public isRunning: boolean = false;
  private _battleOver: boolean = false;
  public get battleOver(): boolean {
    return this._battleOver;
  }

  private battleTimer: number = 0;
  private _gameloopTimer;
  public get gameloopTimer() {
    return this._gameloopTimer;
  }

  private _mounted: boolean;

  //! BEwARE TO CONSIDER DELETING THESE PROPERTIES
  private _stateManager = new Map<
    StateTypes,
    Map<string, React.Dispatch<React.SetStateAction<any>>>
  >();
  private _spawnTimer: Cooldown;
  public get spawnTimer(): Cooldown {
    return this._spawnTimer;
  }

  private _battlelog: BattleMessage[] = [];
  private logLimit = 50;

  // PIXI
  private _gui!: BattleController;
  public get gui(): BattleController {
    return this._gui;
  }

  public constructor(id: string) {
    this.id = id;
    this._mounted = true;

    this._battlelog = [];

    //! BEwARE TO CONSIDER DELETING THESE PROPERTIES
    this._spawnTimer = new Cooldown(this);
    CooldownManager.getInstance().map.set(
      COOLDOWN_TYPE.SPAWN,
      this._spawnTimer
    );
    for (const st in StateTypes) {
      this._stateManager.set(
        st as StateTypes,
        new Map<string, React.Dispatch<React.SetStateAction<any>>>()
      );
    }
  }

  public update(delta: number) {
    if (this.isRunning) {
      //! Timer for resetting game
      /*
      if (this.battleTimer > 0) {
        this.battleTimer -= delta;
        if (this.battleTimer <= 0) {
          this.battleTimer = 0;
          this.nextBattle();
        }
        return;
      }
      */
      if (this.gameIsOver()) {
        if (this._mounted) {
          this._stateManager
            .get(StateTypes.BATTLETIMER)
            ?.forEach((setState) => {
              setState({ attacking: this.isRunning, next: this._battleOver });
            });
        }
      }
      this._battleObjects.forEach((e) => {
        e.update(delta);
      });
    }
  }

  public get stateManager() {
    return this._stateManager;
  }

  public get mounted(): boolean {
    return this._mounted;
  }
  public set mounted(value: boolean) {
    this._mounted = value;
  }

  public get battlelog(): BattleMessage[] {
    return this._battlelog;
  }

  public startAttack() {
    if (!this.isRunning) {
      if (this.gameIsOver()) {
        this.nextBattle();
      } else {
        this.battleObjects.forEach((e) => {
          if (e.stateMachine.previousState) {
            e.stateMachine.nextState = e.stateMachine.previousState;
          } else {
            e.stateMachine.nextState = ENTITY_STATE.SEARCH;
          }
        });
      }
    } else {
      this.battleObjects.forEach((e) => {
        if (e.stats.health.current > 0) {
          e.stateMachine.nextState = ENTITY_STATE.WAIT;
          e.animation?.stateQueue.push(ENTITY_STATE.WAIT);
          e.update(0);
        }
      });
    }
    this.isRunning = !this.isRunning;
  }

  public updateSelected(e: Entity | undefined) {
    if (this._mounted) {
      this._stateManager.get(StateTypes.ENTITY)?.forEach((setState) => {
        setState({ focus: e });
      });
      this._gui.selectEntity(e!);
    }
  }
  public updateSummon(e: Entity | undefined) {}
  public updateLog() {
    if (this._battlelog.length > this.logLimit) {
      // More efficient than array.shift() => O(n)
      // array.reverse().pop() => O(1);
      this._battlelog.reverse();
      while (this._battlelog.length > this.logLimit) {
        this._battlelog.pop();
      }
      this._battlelog.reverse();
    }

    if (this._mounted) {
      this._stateManager.get(StateTypes.BATTLELOG)?.forEach((setState) => {
        setState({ battlelog: this._battlelog });
      });
    }
  }

  public updateMinion(minion: Minion) {
    if (this._mounted) {
      const setState = this._stateManager
        .get(StateTypes.MINION)
        ?.get(minion.id.toString());

      if (setState !== undefined) setState({ minion: minion });
    }
  }

  public nextBattle() {
    DungeonLoader.nextBattle(this.id, this);
    this._battleObjects.forEach((o) => {
      if (o instanceof Monster) {
        o.position = {
          x: GameController.WIDTH - randomInt(40, 120),
          y: randomInt(0, GameController.HEIGHT - 50),
        };
      } else {
        o.position = {
          x: randomInt(0, 120),
          y: randomInt(0, GameController.HEIGHT - 50),
        };
      }
      this._battleOver = false;
      o.stats.health.current = o.stats.health.max;
      o.animation?.sprite.updateHealthBar();
      o.stateMachine.currentState = ENTITY_STATE.SEARCH;
    });
  }

  public generateMonsters(x: number, id: string) {
    for (let i = 0; i < x; i++) {
      let m = MonsterLoader.loadMonster(id);
      m.position = {
        x: randomInt(GameController.WIDTH / 2, GameController.WIDTH - 50),
        y: randomInt(0, GameController.HEIGHT - 50),
      };
      m.assignedBattle = this;

      m.animation?.sprite.hitBox.on("click", (event) => {
        this.updateSelected(m);
      });
      m.animation?.sprite.hitBox.on("tap", (event) => {
        this.updateSelected(m);
      });

      m.physics.direction = DIRECTION.LEFT;
      this._battleObjects.push(m);
    }
  }

  public generateMinions(x: number) {
    for (let i = 0; i < x; i++) {
      let m = new Minion("Dao" + i, 1, undefined, 10, "Noob", "ReKnight");
      m.position = {
        x: randomInt(0, 220),
        y: randomInt(0, GameController.HEIGHT - 50),
      };
      m.assignedBattle = this;

      m.animation?.sprite.hitBox.on("click", (event) => {
        this.updateSelected(m);
      });
      m.animation?.sprite.hitBox.on("tap", (event) => {
        this.updateSelected(m);
      });

      this._battleObjects.push(m);
    }
  }

  public generatePlayer() {
    this.player.position = {
      x: randomInt(0, 120),
      y: randomInt(0, GameController.HEIGHT - 50),
    };

    this.player.assignedBattle = this;

    this.player.animation?.sprite.hitBox.on("click", (event) => {
      this.updateSelected(this.player);
    });
    this.player.animation?.sprite.hitBox.on("tap", (event) => {
      this.updateSelected(this.player);
    });

    this._battleObjects.push(this.player);
  }

  public loadGui() {
    this._gui = new BattleController(this);
    this._gameloopTimer = null;
  }

  private gameIsOver() {
    if (
      this._battleObjects.filter(
        (x) => !(x instanceof Monster) && x.stats.health.current > 0
      ).length === 0 ||
      this._battleObjects.filter(
        (x) => x instanceof Monster && x.stats.health.current > 0
      ).length === 0
    ) {
      this._battleOver = true;
      return true;
    }
    this._battleOver = false;
    return false;
  }

  public removeObject(e: Monster | Player | Summon) {
    const index = this._battleObjects.indexOf(e);
    if (index !== undefined && index > -1) {
      this._battleObjects.splice(index, 1);
      this._gui.removeObject(e.animation!.sprite);
    }
  }
  public addObject(e: Monster | Player | Summon) {
    this._battleObjects.push(e);
    this._gui.addObject(e.animation!.sprite);
  }

  public removeMonsters() {
    this._battleObjects = this._battleObjects.filter(
      (e) => !(e instanceof Monster)
    );
  }

  public hardUpdateGui() {
    this._gui.hardUpdateObjects();
  }

  public selectSummonable(e?: Entity) {
    const s = this._battleObjects.filter(
      (x) =>
        x instanceof Monster &&
        (x.animation?.sprite as MonsterSprite).summonAbleEffect.visible
    );

    if (s.length > 0) {
      this.updateSelected(s.pop());
      return;
    }
    this.updateSelected(e);
  }
}
