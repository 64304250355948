import { closeToAttack } from "../../../Utils/Calculation";
import { ENTITY_STATE } from "../State Machine/EntityStateMachine";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";

export class CombatComponent extends Component implements ComponentInterface {
  private attackTimer: number = 0;
  private attackCooldown: number = 1000;
  private lastUpdate: number = 0;

  public update(delta: number) {
    const now = performance.now();
    this.attackTimer -= now - this.lastUpdate;
    this.lastUpdate = now;

    if (this.e.stateMachine.currentState === ENTITY_STATE.COMBAT) {
      if (this.e.stateMachine.previousState !== ENTITY_STATE.COMBAT)
        this.e.animation?.stateQueue.push(ENTITY_STATE.WAIT);

      //TODO: SET CORRECT ANIMATION, A LOOK FOR WHEN IT IS DONE!
      if (this.e.target) {
        if (this.e.target.stats.health.current <= 0) {
          this.e.stateMachine.nextState = ENTITY_STATE.SEARCH;
          return;
        }

        if (!closeToAttack(this.e, this.e.target)) {
          this.e.stateMachine.nextState = ENTITY_STATE.MOVE;
          return;
        }
      }

      if (this.attackTimer <= 0) {
        //! CAN ATTACK!
        this.attackCooldown = 1000 / this.e.stats.attackSpeed.finalValue;
        this.e.animation?.stateQueue.push(ENTITY_STATE.ATTACK);
        this.attackTimer = this.attackCooldown;
      }
      //! COOLDOWN, IS A CALLBACK FROM WHEN ATTACK IS DONE, GOES TO WAIT
    }
  }
}
