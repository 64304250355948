import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Player } from "../../../Models/Entities/Player";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";

export enum STAT {
  STRENGTH,
  DEXTERITY,
  INTELLIGENCE,
  VITALITY,
}

export const MainStats = () => {
  const playerUpdater = useSelector((state) => state.playerUpdater);
  let dispatch = useDispatch();
  let player: Player = playerUpdater.payload.player;

  let skillPoints = false;
  if (player.skillPoints > 0) {
    skillPoints = true;
  }
  return (
    <div>
      <h3 style={{ marginTop: "0px", paddingRight: "1vw" }}>Main stats:</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <div
          style={{
            marginRight: "2vw",
          }}
        >
          <p>Strength:</p>
          <p>Dexterity:</p>
          <p>Intelligence:</p>
          <p>Vitality:</p>
        </div>
        <div
          style={{
            marginRight: "2vw",
            textAlign: "initial",
          }}
        >
          <p>{player.stats.strength.finalValue}</p>
          <p>{player.stats.dexterity.finalValue}</p>
          <p>{player.stats.intelligence.finalValue}</p>
          <p>{player.stats.vitality.finalValue}</p>
        </div>
        {skillPoints && (
          <div
            style={{
              justifyContent: "space-evenly",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <button
              onClick={() => {
                incStat(STAT.STRENGTH);
              }}
            >
              +
            </button>
            <button
              onClick={() => {
                incStat(STAT.DEXTERITY);
              }}
            >
              +
            </button>
            <button
              onClick={() => {
                incStat(STAT.INTELLIGENCE);
              }}
            >
              +
            </button>
            <button
              onClick={() => {
                incStat(STAT.VITALITY);
              }}
            >
              +
            </button>
          </div>
        )}
      </div>
    </div>
  );

  function incStat(stat: STAT) {
    if (player.skillPoints <= 0) {
      return;
    }
    switch (stat) {
      case STAT.STRENGTH:
        player.stats.strength.baseValue++;
        break;
      case STAT.DEXTERITY:
        player.stats.dexterity.baseValue++;
        break;
      case STAT.INTELLIGENCE:
        player.stats.intelligence.baseValue++;
        break;
      default:
        player.stats.vitality.baseValue++;
        break;
    }
    player.skillPoints--;
    console.log(player);
    dispatch(updatePlayer(Player.getInstance()));
  }
};
