import { Battle } from "../../Core/BattleEngine/Battle";
import Dungeons from "../../Models/Data/dungeons.json";
import { randomInt } from "../Calculation";

interface BattleInterface {
  name: string;
  level: number;
  monsters: object;
  summonable: boolean;
}

export class DungeonLoader {
  public static loadDungeon(id: string): Battle {
    const battle = new Battle(id);
    DungeonLoader.loadMonster(id, battle);
    battle.generateMinions(5);
    if (id === "Cave") {
      battle.generatePlayer();
    }
    battle.loadGui();
    return battle;
  }

  public static nextBattle(id: string, battle: Battle) {
    battle.removeMonsters();
    DungeonLoader.loadMonster(id, battle);
    battle.hardUpdateGui();
  }

  private static loadMonster(id: string, battle: Battle) {
    const b: BattleInterface = Dungeons[id];

    Object.keys(b.monsters).forEach((m) => {
      const number = Math.round(
        randomInt(
          b.monsters[m] - b.monsters[m] * 0.25,
          b.monsters[m] + b.monsters[m] * 0.25
        )
      );
      if (number <= 0) {
        battle.generateMonsters(1, m);
      } else {
        battle.generateMonsters(number, m);
      }
    });
  }
}
