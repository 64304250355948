import { Game } from "../../../Core/GameEngine/Game";
import { updatePlayer } from "../../../Utils/Store/Actions/PlayerAction";
import { Entity } from "../Entity";
import { Player } from "../Player";
import { Health } from "../../Stats/Attributes/SubAttributes/Health";
import { HealthRegen } from "../../Stats/Attributes/SubAttributes/HealthRegen";
import { Mana } from "../../Stats/Attributes/SubAttributes/Mana";
import { ManaRegen } from "../../Stats/Attributes/SubAttributes/ManaRegen";
import { Component } from "./Component";
import { ComponentInterface } from "./ComponentInterface";

export class RegenerationComponent
  extends Component
  implements ComponentInterface {
  protected regenTimer: number = 0;
  protected regenCooldown: number = 1000;
  protected stat: Health | Mana;
  protected regen: HealthRegen | ManaRegen;

  public constructor(
    e: Entity | Player,
    stat: Health | Mana,
    regen: HealthRegen | ManaRegen
  ) {
    super(e);
    this.stat = stat;
    this.regen = regen;
  }
  public update(delta: number) {
    this.regenTimer -= delta;
    if (this.regenTimer <= 0) {
      if (this.stat.max > this.stat.current) {
        this.stat.current += this.regen.finalValue;
        this.regenTimer = this.regenCooldown;
        Game.dispatch(updatePlayer(Game.Player));
        this.e.animation?.sprite.updateHealthBar();
      }
    }
  }
}
