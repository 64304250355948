import { Spritesheet } from "pixi.js";
import { Battle } from "../../Core/BattleEngine/Battle";
import {
  BattleMessage,
  BattleMessageType,
} from "../../Core/BattleEngine/BattleMessage";
import { EntitySprite } from "../../Core/PixiJS/Entities/EntitySprite";
import { AssetLoader } from "../../Utils/Loaders/AssetLoader";
import { Action } from "../Actions/Action";
import { IdleAction } from "../Actions/IdleActions/IdleAction";
import { AnimationComponent } from "./Entity-Components/AnimationComponent";
import { CombatComponent } from "./Entity-Components/CombatComponent";
import { PathfindingComponent } from "./Entity-Components/PathfindingComponent";
import PhysicsComponent from "./Entity-Components/PhysicsComponent";
import { TargetingComponent } from "./Entity-Components/TargetingComponent";
import { Stats } from "../Stats/Stats";
import {
  EntityStateMachine,
  ENTITY_STATE,
} from "./State Machine/EntityStateMachine";
import { Monster } from "./Monsters/Monster";

export abstract class Entity {
  // TODO: Only add components that are not dependent on the other classes.
  //! Else we will get a circular dependency that will break the game.

  private uuid;

  private _className: string = "Entity";

  public get className() {
    return this._className;
  }

  public nextAction: Action;
  public stateMachine: EntityStateMachine;

  protected _name;
  protected _level: number;
  protected _stats: Stats;

  protected spriteName: string;

  protected targeting: TargetingComponent;
  protected pathing: PathfindingComponent;
  protected _physics: PhysicsComponent;
  protected _animation?: AnimationComponent = undefined;
  protected combat: CombatComponent;

  protected _target?: Entity = undefined;
  private _assignedBattle?: Battle = undefined;

  protected _position = {
    x: 0,
    y: 0,
  };

  protected _centerPosition = {
    x: 0,
    y: 0,
  };

  protected _velocity = {
    dx: 0,
    dy: 0,
  };

  constructor(className: string, name: string, level: number, sprite: string) {
    this._className = className;

    this._name = name;
    this._level = level;
    this._stats = new Stats();

    this.spriteName = sprite;

    this.nextAction = new IdleAction(this);
    this.stateMachine = new EntityStateMachine();

    this.targeting = new TargetingComponent(this);
    this.pathing = new PathfindingComponent(this);
    this._physics = new PhysicsComponent(this);
    this.combat = new CombatComponent(this);

    if (AssetLoader.getInstance().spriteSheets.size > 0) {
      this._animation = new AnimationComponent(
        this,
        AssetLoader.getInstance().spriteSheets.get(sprite) as Spritesheet
      );
    }
  }

  public loadSprite() {
    if (!this._animation) {
      if (AssetLoader.getInstance().spriteSheets.size > 0) {
        this._animation = new AnimationComponent(
          this,
          AssetLoader.getInstance().spriteSheets.get(
            this.spriteName
          ) as Spritesheet
        );
      }
    }
  }

  public update(delta: number) {
    this.stateMachine.previousState = this.stateMachine.currentState;
    if (this.stateMachine.nextState) {
      this.stateMachine.currentState = this.stateMachine.nextState;
      this.stateMachine.nextState = undefined;
    }

    this.targeting.update(delta);
    this.pathing.update(delta);
    this._physics.update(delta);
    this.combat.update(delta);

    switch (this.stateMachine.currentState) {
      case ENTITY_STATE.DEAD:
        if (this.stateMachine.previousState !== this.stateMachine.currentState)
          this._animation?.stateQueue.push(ENTITY_STATE.DEAD);
        break;
      case ENTITY_STATE.REANIMATE:
        if (this.stateMachine.previousState !== this.stateMachine.currentState)
          this._animation?.stateQueue.push(ENTITY_STATE.REANIMATE);
        break;
      default:
    }
    this._animation?.update(delta);
  }

  public get name() {
    return this._name;
  }

  public get position() {
    return this._position;
  }

  public set position(value) {
    this._position = value;

    if (this._animation) {
      const w = this._animation.sprite.hitBox.width;
      const h = this._animation.sprite.hitBox.height;
      this._centerPosition = { x: value.x + w / 2, y: value.y + h / 2 };
      this._animation.sprite.setPosition(value.x, value.y);
    }
  }

  public get velocity() {
    return this._velocity;
  }
  public set velocity(value) {
    this._velocity = value;
  }

  public get assignedBattle(): Battle | undefined {
    return this._assignedBattle;
  }
  public set assignedBattle(value: Battle | undefined) {
    this._assignedBattle = value;
  }

  public get centerPosition() {
    return this._centerPosition;
  }

  public get stats(): Stats {
    return this._stats;
  }
  public get level(): number {
    return this._level;
  }

  public get target() {
    return this._target;
  }
  public set target(value) {
    this._target = value;
  }

  public get animation() {
    return this._animation;
  }

  public get physics() {
    return this._physics;
  }

  public basicAttack(): number {
    const baseDamage = this._stats.attack.finalValue;
    if (willCrit(this))
      return baseDamage * this._stats.critMultiplier.finalValue;
    return baseDamage;

    function willCrit(self: Entity) {
      let rand = Math.random() * 100;
      if (rand <= self._stats.critChance.finalValue) return true;
      return false;
    }
  }

  public createBattleMessage(type: BattleMessageType, damage?: number) {
    let m;
    switch (type) {
      case BattleMessageType.ATTACK:
        m = new BattleMessage(type, this.name, this.target?.name, damage);
        break;
      case BattleMessageType.KILLED:
        const t = this.target as Monster;
        m = new BattleMessage(type, this.name, t.name, undefined, t.gold, t.xp);
        break;
      case BattleMessageType.DIED:
        m = new BattleMessage(type, this.name, this.target?.name);
        break;
      default:
        break;
    }
    this._assignedBattle?.battlelog.push(m);
    this._assignedBattle?.updateLog();
  }

  public calcRange(range: number = 1): number {
    if (this._animation) {
      return this.radius() * range;
    }
    console.log("GOT NO RANGE:");
    console.log(this);
    return 0;
  }

  public radius(): number {
    if (this._animation) {
      const a = (this._animation.sprite as EntitySprite).hitBox.width;
      const b = (this._animation.sprite as EntitySprite).hitBox.height;
      const c = Math.sqrt(a * a + b * b);
      return c / 2;
    }
    console.log("GOT NO RADIUS:");
    console.log(this);
    return 0;
  }
}
