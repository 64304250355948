export enum Page {
  Player,
  Inventory,
  Train,
  Dungeon,
  Shop,
  Settings,
}

export class PageManager {
  private static instance: PageManager;
  private _currentPage: Page = Page.Player;

  private constructor() {}

  public static getInstance(): PageManager {
    if (!PageManager.instance) {
      PageManager.instance = new PageManager();
    }
    return PageManager.instance;
  }

  public changePage(page: Page) {
    this._currentPage = page;
  }
  public get currentPage(): Page {
    return this._currentPage;
  }
}
