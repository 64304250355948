import { GameController } from "../GameController";
import { Battle } from "../../BattleEngine/Battle";
import { EntitySprite } from "../Entities/EntitySprite";
import { Entity } from "../../../Models/Entities/Entity";
import { DIRECTION } from "../../../Models/Entities/Entity-Components/PhysicsComponent";
import { Container, Sprite, Texture } from "pixi.js";

export class BattleController extends GameController {
  private lastUpdate = 0;
  private timeDiff = 2000;

  private battle: Battle;

  private selectedEntity?: Entity;
  private entityContainer: Container = new Container();

  constructor(battle: Battle) {
    super();
    this.battle = battle;
    this.setup();
  }

  private requestFrame: number = 0;

  private setup() {
    this.viewport.sortableChildren = true;
    this.battle.battleObjects.forEach((o) => {
      if (o.animation) this.viewport.addChild(o.animation.sprite);
    });

    this.stage.addChild(this.entityContainer);
  }

  protected gameloop(timestamp) {
    if (this.lastUpdate === 0) {
      this.lastUpdate = timestamp;
      requestAnimationFrame(this.gameloop.bind(this));
      return;
    }
    const elapsedTime = timestamp - this.lastUpdate;
    this.timeDiff -= elapsedTime;
    if (this.timeDiff <= 0) {
      this.timeDiff = 2000;
    }
    this.lastUpdate = timestamp;

    this.state();
    if (this.state !== this.destroy && this.state !== this.stop) {
      requestAnimationFrame(this.gameloop.bind(this));
      this.renderer.render(this.stage);
    }
  }

  public start() {
    this.addBackgroundToThumbnail();
    this.state = this.play;
    requestAnimationFrame(this.gameloop.bind(this));
  }

  protected play() {
    this.battle.battleObjects.forEach((e) => {
      e.physics.direction = this.directionToTarget(e);
      e.animation?.updateDirection(e.physics.direction);
    });

    if (this.selectedEntity) {
      this.selectedEntity.physics.direction = this.directionToTarget(
        this.selectedEntity
      );
      this.selectedEntity.animation?.updateDirection(
        this.selectedEntity.physics.direction
      );
    }
  }

  private directionToTarget(e: Entity) {
    let dir = e.physics.direction;
    if (e.target) {
      if (e.centerPosition.x - e.target.centerPosition.x < 0) {
        dir = DIRECTION.RIGHT;
      } else dir = DIRECTION.LEFT;
    }
    return dir;
  }

  public addObject(e: EntitySprite) {
    this.viewport.addChild(e);
  }
  public removeObject(e: EntitySprite) {
    this.viewport.removeChild(e);
  }
  public hardUpdateObjects() {
    this.viewport.removeChildren();
    this.setup();
  }
  public stop() {}

  public selectEntity(e: Entity) {
    this.selectedEntity = e;

    if (!e) {
      if (this.entityContainer.children.length > 1) {
        console.log(this.entityContainer.children.length);
        this.entityContainer.removeChildAt(
          this.entityContainer.children.length - 1
        );
      }
      return;
    }

    if (this.entityContainer.children.length > 1) {
      console.log(this.entityContainer.children.length);
      this.entityContainer.removeChildAt(
        this.entityContainer.children.length - 1
      );
    }
    const eSprite = e.animation?.sprite.spriteThumbnail;
    eSprite?.position.set(
      40 - e.animation?.sprite.hitBox.height! / 2,
      40 - e.animation?.sprite.hitBox.width! / 2
    );
    this.entityContainer.addChild(eSprite!);
  }

  public addBackgroundToThumbnail() {
    this.entityContainer.removeChildren();
    const sprite = new Sprite(Texture.WHITE);
    sprite.width = 80;
    sprite.height = 80;
    sprite.texture = Texture.WHITE;
    sprite.tint = 0x000000;
    sprite.alpha = 0.8;

    let densityRatio = 0;
    //! CHECK IF DENSITY OF PIXEL IS DIFFERENT
    if (window.devicePixelRatio === 1.5) {
      if (this._renderer.width < this._renderer.height) {
        densityRatio = 1 / window.devicePixelRatio;
      } else {
        densityRatio = 1.25;
      }
    }
    this.entityContainer.position.set(
      22,
      this._renderer.height / this._renderer.resolution -
        sprite.height / 2 -
        77 +
        densityRatio
    );
    this.entityContainer.addChild(sprite);
  }
}
